import React, {useEffect, useReducer, useState} from 'react';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	ButtonType,
	DateTimeRange, Dropdown,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	QueryResultColumns,
	QueryResultPanel,
	QueryResultRow,
	Text,
	WorkAreaTitle
} from '../component';
import {
	findOperationRecordDataPage, OperationLogPage,
	TraceCriteria,
	TraceDataPage,
	exportOperationRecordData
} from '../service/trace-service';
import {endOfDate, formatFull, startOfDate} from "../service/date";
import {AccountData, getTenantList} from "../service/account-service";
import {AccountList} from "../service/record-service";
import {DropdownOption} from "../component/dropdown";
import {isAdminDisplay} from '../utils';
const TraceQuery = styled.div`
	display: flex;
	flex-direction: column;
`;

const ButtonWrapper = styled.div`
	width: max-content;
	text-align: center;
	> *:last-child {
		display: inline-block;
		margin-left: var(--gap-thin);
	}
`;

const columns: QueryResultColumns = [
	{ label: '#', width: '40px', name: 'seq' },
	{
		label: <React.Fragment>
			<div>回溯号</div>
		</React.Fragment>, width: '250px', name: [ 'traceNo' ]
	},
	{
		label: <React.Fragment>
			<div>来源</div>
		</React.Fragment>, width: '100px', name: [ 'source' ]
	},
	{
		label: <React.Fragment>
			<div>报价单号</div>
			<div>保单号</div>
			<div>订单号</div>
		</React.Fragment>, width: '200px', name: [ 'quoteNo', 'policyNo', 'orderNo' ]
	},
	{
		label: <React.Fragment>
			<div>付款号</div>
			<div>支付订单号</div>
		</React.Fragment>, width: '200px', name: [ 'payNo', 'payOrderNo' ]
	},
	{
		label: <React.Fragment>
			<div>操作方式</div>
		</React.Fragment>, width: '80px', name: [ 'operationType' ]
	},
	{
		label: <React.Fragment>
			<div>操作人</div>
		</React.Fragment>, width: '100px', name: [ 'operationUserName' ]
	},
	{
		label: <React.Fragment>
			<div>操作时间</div>
		</React.Fragment>, width: '120px', name: [ 'createAt' ]
	}
	];

type InputCriteria = Exclude<keyof TraceCriteria, 'pageSize' | 'pageNumber'>

export default () => {
	const now = new Date();

	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
	const [ data, setData ] = useState<OperationLogPage | null>(null);
	const [ searched, setSearched ] = useState(false);
	const [tenantList,setTenantList] = useState<Array<DropdownOption> | []>([]);
	const [ criteria ] = useState<TraceCriteria>({
		createFrom: formatFull(startOfDate(now)),
		createTo: formatFull(endOfDate(now)),
		traceNo: ''
	});

	useEffect(() => {
		(async () => {
			const tenantlist : AccountList =await getTenantList();
			const accounts = tenantlist?.accounts || [];
			const tenant = new Array<DropdownOption>({value:'',label:'请选择'});
			accounts.forEach((account)=>{
				tenant.push({value:account.tenantId,label:account.accountName});
			});
			setTenantList(tenant);
		})();
	}, []);

	const [ currentCriteria, setCurrentCriteria ] = useState<TraceCriteria | null>(null);

	const onChanged = (propName: InputCriteria) => (event: React.ChangeEvent) => {
		// @ts-ignore
		criteria[propName] = (event.target as HTMLInputElement).value;
		forceUpdate();
	};

	const doSearch = async (pagination: TraceCriteria) => {
		// 设置当前查询
		setCurrentCriteria(pagination);
		const data = await findOperationRecordDataPage(pagination);
		setData(data);
		setSearched(true);
	};
	const onSearchClicked = async (criteria: TraceCriteria) => {
		const pagination = {
			...criteria,
			pageNumber: 1,
			pageSize: 10
		};
		await doSearch(pagination);
	};

	const onExportClicked = async(criteria: TraceCriteria) =>{
		const pagination = {
			...criteria,
			pageNumber: 1,
			pageSize: 10
		};
		await doExport(pagination);
	}

	const onPageChanged = async (pageNumber: number) => {
		const pagination = {
			...currentCriteria,
			pageNumber
		};
		await doSearch(pagination);
	};

	const doExport =  (pagenation:TraceCriteria)=>{
		setCurrentCriteria(pagenation);
		exportOperationRecordData(pagenation);
  };



	const dataFound = data || { pageNumber: 1, pageSize: 10, totalPages: 1, totalItemCount: 0, rows: [] };

	const rows = (dataFound.rows || []).map((row): QueryResultRow => {
		return row as QueryResultRow;
	});

	const onDateChanged = (startDate?: Date, endDate?: Date) => {
		criteria.createFrom = formatFull(startOfDate(startDate));
		criteria.createTo = formatFull(endOfDate(endDate));
		forceUpdate();
	};
	const traceUpdate = () => {
		forceUpdate();
	};
	// noinspection DuplicatedCode
	return <TraceQuery>
		<WorkAreaTitle title="可回溯记录访问轨迹"/>
		<QueryPanel>
			<DateTimeRange label="操作时间" columns={2} startDate={criteria.createFrom!} endDate={criteria.createTo!}
						   onChange={onDateChanged} update={traceUpdate} />
			<Text label="来源" placeholder="请输入" value={criteria.source} onChange={onChanged('source')}/>
			<Text label="业务回溯码" placeholder="请输入" value={criteria.traceNo} onChange={onChanged('traceNo')}/>
			{isAdminDisplay() && <Dropdown placeholder="请输入" label="所属系统" options={tenantList} value = {criteria.tenantId?.toString()} onChange ={onChanged("tenantId")}/>}
			<Text label="保单号" placeholder="请输入" value={criteria.policyNo} onChange={onChanged('policyNo')}/>
			<Text label="报价单号" placeholder="请输入" value={criteria.quoteNo} onChange={onChanged('quoteNo')}/>
			<Text label="订单号" placeholder="请输入" value={criteria.orderNo} onChange={onChanged('orderNo')}/>
			<Text label="付款号" placeholder="请输入" value={criteria.payNo} onChange={onChanged('payNo')}/>
			<Text label="支付订单号" placeholder="请输入" value={criteria.payOrderNo} onChange={onChanged('payOrderNo')}/>
			<PanelSeparator columns={3}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="查询" onClick={() => onSearchClicked(criteria)}/>
				<Button label="导出" onClick={() => onExportClicked(criteria)} />
			</ButtonBar>
			<PanelSeparator columns={3}/>
		</QueryPanel>
		<FormSectionGapVertical/>
		<QueryResultPanel columns={columns} rows={rows} pagination={true} searched={searched}
						  pageCount={dataFound.totalPages} pageNumber={dataFound.pageNumber}
						  totalItemCount={dataFound.totalItemCount}
						  pageChange={onPageChanged}/>
	</TraceQuery>;
}