import { isAdminLogin,isTenantAdminLogin,isPlatformLogin,isBillAdminLogin } from './service/storage';
import Account from "./service/account-model";
export const isDemoHide = () => {
	return window.location.href.startsWith("https://glutton.ebaocloud.com.cn");
};
export const isBlockChainHide = () => {
	return false;
};
export const isCCICDisplay = () => {
	return window.location.host.indexOf('ccic-net.com.cn') >= 0 ||window.location.host.indexOf('ccic-test.com.cn') >= 0;
};

export const isPublicCloud = () => {
	return window.location.host.indexOf('ebaocloud.com.cn') != -1 || window.location.host.indexOf('localhost') != -1;
};

export const isMEJ = () => {
	return sessionStorage.getItem("glutton-account-tenantId") == '20088';
};

export const isAdminDisplay = () => {
	return isAdminLogin();
};

export const isTenantAdminDisplay = () => {
	return isTenantAdminLogin();
};

export const isPlatformDisplay = () => {
	return isPlatformLogin();
};

export const isBillAdminDisplay = () => {
	return isBillAdminLogin();
};

export const isAuthorized = () => {
    const accountString = sessionStorage.getItem("glutton-account");
    if(accountString) {
        try {
            const account: Account = JSON.parse(accountString);
            return !!(account.accountName && account.token);
        } catch {
            return false;
        }
    }else{
        return false;
    }
};