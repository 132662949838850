import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonBar, ButtonType, Text } from './component';
import Account from './service/account-model';
import { signIn, SignInBean } from './service/security-service';
import { isCCICDisplay,isPublicCloud } from './utils';
import { validateCcicTicket } from './ccic/cas-service';
import { validatePublicCloudToken } from './publicCloud/csms-service';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 500px;
	margin: calc(var(--side-margin) * 2) auto auto;
	border: 1px solid var(--primary-color);
	border-radius: var(--radius);
	padding: var(--side-margin) var(--side-margin) var(--gap);
	> div:last-child {
		margin-top: var(--gap);
		text-align: right;
	}
	@media (max-width: 599px) {
		width: 75vw;
		border: 0;
		padding: 0;
	}
`;
const ErrorLabel = styled.div`
	color: var(--danger-color);
	padding: var(--gap) 0 0;
	line-height: var(--input-height);
	//height: 0;
	opacity: 0;
	transition: all 300ms ease-in-out;
	&[data-show=true] {
		//height: calc(var(--gap) * 2 + var(--input-height));
		opacity: 1;
	}
`;

export default (props: { onSigned: (account: Account) => void }) => {
	const { onSigned } = props;

	if (isCCICDisplay()) {
		validateCcicTicket(onSigned);
		return <></>;
	}

	if(isPublicCloud()) {
		validatePublicCloudToken(onSigned);
		return <></>;
	}

	const nameRef = useRef<HTMLInputElement>(null);
	const pwdRef = useRef<HTMLInputElement>(null);
	const [ fail, setFail ] = useState<string | null>(null);

	const criteria: SignInBean = { id: '', credential: '' };

	const onSignInClicked = async () => {
		try {
			criteria.id = nameRef.current!.value;
			criteria.credential = pwdRef.current!.value;
			const account = await signIn(criteria);
			if(!account.isAuthorized){
				setFail("5分钟内登录失败超过5次,请5分钟后重试!");
			}else{
				setFail(null);
				onSigned(account);
				window.location.replace(window.location.href.split(window.location.host)[0]+window.location.host);
			}
		} catch {
			setFail("登录失败, 请检查用户名和密码之后重试.");
		}
	};

	return <Container>
		<Text label="用户名" placeholder="请输入" defaultValue={criteria.id} ref={nameRef}/>
		<Text type="password" label="密码" placeholder="请输入" defaultValue={criteria.credential} ref={pwdRef}/>
		<ErrorLabel data-show={fail != null}>{fail}</ErrorLabel>
		<ButtonBar columns={1}>
			<Button label="登录" type={ButtonType.PRIMARY} onClick={onSignInClicked}/>
		</ButtonBar>
	</Container>;
}