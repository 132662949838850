import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import Audit from './audit/audit-index';
import Demo from './demo/demo';
import { AccountProvider } from './hooks/account';
import { useResponsive } from './hooks/responsive';
import Path from './path';
import Trace from './trace/trace-index';
import FullTrace from './full-trace/full-trace-index';
import TraceDownload from './trace/trace-download';
import GluttonUser from './user/user-index';

import TraceRecord from './record/trace-record/record-index';
import BlockchainRecord from './record/blockchain-record/record-index';
import Account from './account/account-index';
import QualityInspection from './qualityInspection/qualityInspection-index';
import QualityInspectionTask from './qualityInspectionTask/qualityInspectionTask-index';
import Mask from './mask/mask-index';
import SourceConfig from './sourceConfig/sourceConfig-index';
import OperationRecord from './operationRecord/operationRecord-query';
import {isBillAdminDisplay,isDemoHide, isCCICDisplay, isAdminDisplay, isTenantAdminDisplay,isMEJ} from './utils';
import TraceInspection from './traceInspection/trace-inspection-index';
import SystemOperationLog from './systemOperation/systemOperation-query';
import TraceInspectionReport from './traceInspectionReport/trace-inspection-report-query';

const WorkArea = styled.div`
	flex-grow: 1;
	padding: var(--gap) var(--side-margin);
`;

export default () => {
	const responsive = useResponsive();
	if (responsive.mobile) {
		return <WorkArea>
			<AccountProvider>
				<Switch>
					<Route path={Path.DEMO}>
						<Demo/>
					</Route>
				</Switch>
			</AccountProvider>
		</WorkArea>;
	}

	return <WorkArea>
		<AccountProvider>
			<Switch>
				<Route path={Path.TRACE}>
					<Trace/>
				</Route>
				<Route path={Path.FULL_TRACE}>
					<FullTrace/>
				</Route>
				{/*{isCCICDisplay()
					? null
					: <Route path={Path.AUDIT}>
						<Audit/>
					</Route>
				}*/}
				{isAdminDisplay() || isTenantAdminDisplay()
					? <Route path={Path.ACCOUNT}>
						<Account/>
					</Route>
					: null}
				{isAdminDisplay() || isMEJ()
					? <Route path={Path.OPERATION_LOG}>
					<OperationRecord/>
				</Route>
					: null}

				<Route path={Path.MASK}>
					<Mask/>
				</Route>

				{isTenantAdminDisplay() || isAdminDisplay()
				? <Route path={Path.SOURCE_CONFIG}>
					<SourceConfig/>
				</Route>
					: null}

				{isAdminDisplay()
				? <Route path={Path.GLUTTON_USER}>
					<GluttonUser/>
				</Route>
					: null}

				{(isAdminDisplay() || isBillAdminDisplay())
				? <Route path={Path.RECORD_TRACE_QUERY}>
                	<TraceRecord/>
                </Route> : null}

				{(isAdminDisplay() || isBillAdminDisplay())
				? <Route path={Path.RECORD_BLOCKCHAIN_QUERY}>
                	<BlockchainRecord/>
                </Route> : null}

				<Route path={Path.TRACE_DOWNLOAD_QUERY}>
					<TraceDownload/>
				</Route>

				{isTenantAdminDisplay() || isAdminDisplay()
                ? <Route path={Path.QUALITY_INSPECTION}>
                    <QualityInspection/>
                </Route> : null}

				{isTenantAdminDisplay() || isAdminDisplay()
				? <Route path={Path.QUALITY_INSPECTION_TASK}>
					<QualityInspectionTask/>
				</Route> : null}

				{isAdminDisplay()
					? <Route path={Path.TRACE_REPORT_MONTH}>
						<Trace/>
						</Route>
					: null}

				{isAdminDisplay()
				 ? <Route path={Path.TRACE_REPORT_DAY}>
					<Trace/>
				</Route> : null}

				{isDemoHide()
					? null
					: <Route path={Path.DEMO}>
						<Demo/>
					</Route>}

                {isTenantAdminDisplay() ||isAdminDisplay()
                    ? <Route path={Path.TRACE_INSPECTION}>
                        <TraceInspection/>
                    </Route> : null}

                {isTenantAdminDisplay() ||isAdminDisplay()
                    ? <Route path={Path.TRACE_INSPECTION_REPORT}>
                        <TraceInspectionReport/>
                    </Route> : null}

                {isAdminDisplay()
                    ? <Route path={Path.SYSTEM_OPERATION_LOG}>
                        <SystemOperationLog/>
                    </Route> : null}
				{isBillAdminDisplay()
					? <Route path="*">
						<Redirect to={Path.RECORD_TRACE_QUERY}/>
					  </Route>
					: <Route path="*">
						<Redirect to={Path.TRACE}/>
					  </Route>
				}
			</Switch>
		</AccountProvider>
	</WorkArea>;
}