import pako from 'pako';
import { useEffect, useState } from 'react';
import {
	ElementNode,
	IncrementalEventType,
	IncrementalSnapshotEvent,
	MetaEvent,
	NodeType,
	SerializedNodeWithId,
	ViewportResizeEventData,
	WebEvent,
	WebEventType,
	WebEventWithTime
} from '../catcher/rrweb/types';
import {
	askOssStsToken,
	getFileDownloadUrl,
	listFiles,
	getSignatureUrl,
	OSS,
	OssStsToken,
	restoreArchiveFiles
} from '../service/oss-service';
import { getAccount } from '../service/storage';
import { findCollectedSources, findTraceData, TraceData, WebSource } from '../service/trace-service';
import {
	Details,
	FileContent,
	FileMeta,
	OffsetInMillisecond,
	OssMetaWithContent,
	OssMetaWithUrl,
	SegmentMeta,
	UUID
} from './trace-view-types';
import Account from '../service/account-model';
import {sleep} from "../catcher/oss/utils";
import {ObjectMeta} from "ali-oss";

const getFileUrl = async (meta: OSS.ObjectMeta,ossToken?: OssStsToken | null): Promise<OssMetaWithUrl> => {
	const url = await getFileDownloadUrl(meta.name,ossToken);
	return { meta, url };
};
const extractJson = async (response: Response): Promise<any> => await response.json();
const getFileContent = async (
	file: Promise<OssMetaWithUrl>,
	extract: (response: Response) => Promise<any> = extractJson
): Promise<OssMetaWithContent> => {
	const { meta, url } = await file;
	const response = await fetch(url);
	const content = await extract(response);
	return { meta, url, content };
};
const fetchMetaData = async (files: Array<OSS.ObjectMeta>,ossToken?: OssStsToken | null): Promise<Array<FileMeta>> => {
	return Promise.all(
		files
			.filter(meta => meta.name.endsWith('.json'))
			.map(async file => await getFileUrl(file,ossToken))
			.map(async file => await getFileContent(file))
	);
};
const fetchContentData = (files: Array<OSS.ObjectMeta>,ossToken?: OssStsToken | null): Promise<Array<FileContent>> => {
	return Promise.all(
		files
			.filter(meta => meta.name.endsWith('.rrweb'))
			.map(async file => await getFileUrl(file,ossToken))
			.map(async file => {
				const { meta, url, content } = await getFileContent(file, async (response) => {
					return new Promise<Array<WebEventWithTime> | null>(async resolve => {
						try {
							const blob = await response.blob();
							const reader = new FileReader();
							reader.onload = () => {
								// console.info('Read text from blob.');
								try {
									if (reader.result) {
										// parse as normal
										// @ts-ignore
										resolve(JSON.parse(reader.result));
									}
								} catch (e) {
									try {
										// parse after inflate
										// @ts-ignore
										resolve(JSON.parse(pako.inflate(reader.result, { to: 'string' })));
									} catch (ex) {
										console.error('Error on parse as plain json.', e);
										console.error('Error on parse as deflated json.', ex);
										resolve(null);
									}
								}
							};
							reader.readAsText(blob);
						} catch (e) {
							console.error(e);
							resolve(null);
						}
					});
				});
				const uuidEndIdx = url.indexOf('.rrweb');
				const uuid = url.substring(uuidEndIdx - 32, uuidEndIdx);
				return { meta, url, content, uuid };
			})
	);
};

export const initTraceData = async (traceId: string,createAt: string): Promise<TraceData | null> => {
	return await findTraceData(traceId,createAt);
};

const askSourceCollected = async (sources: Array<WebSource>): Promise<Array<WebSource>> => {
	return await findCollectedSources(sources);
};

export const forceFixHeightForMobileReplay = (events: Array<WebEventWithTime>) => {
	if (events.length !== 0 && (events[0] as MetaEvent).data?.width < 450) {
		// 手机设备
		const fixedScreenHeight = (events[0] as MetaEvent).data?.height;
		events
			.filter(x =>
				(x as WebEvent).type === WebEventType.Meta
				|| (
					(x as WebEvent).type === WebEventType.IncrementalSnapshot
					&& (x as IncrementalSnapshotEvent).data?.source === IncrementalEventType.ViewportResize
				))
			.forEach(x => (x.data as ViewportResizeEventData).height = fixedScreenHeight);
	}
};

export const EventSorts = {
	[`${WebEventType.DomContentLoaded}`]: 1,
	[`${WebEventType.Loaded}`]: 2,
	[`${WebEventType.Meta}`]: 3,
	[`${WebEventType.FullSnapshot}`]: 4,
	[`${WebEventType.IncrementalSnapshot}`]: 5,
	[`${WebEventType.Custom}`]: 6
};
export const sort = (events: Array<WebEventWithTime>) => {
	return (events || []).sort((e1: WebEventWithTime, e2: WebEventWithTime) => {
		if (e1.timestamp !== e2.timestamp) {
			return e1.timestamp - e2.timestamp;
		}
		return (EventSorts[`${e1.type}`] || 0) - (EventSorts[`${e2.type}`] || 0);
	});
};
export const fixSimpleImageFrames = (events: Array<WebEventWithTime>) => {
	let width = 0;
	(events || []).forEach(event => {
		if (event.type === WebEventType.Meta) {
			width = event.data.width;
		}
		if (event.type !== WebEventType.FullSnapshot) {
			return;
		}

		let bodyNode: ElementNode;
		let imageNode: ElementNode;

		const isSingleImage = (node: SerializedNodeWithId): boolean => {
			if (node.type === NodeType.Element && node.tagName === 'body') {
				bodyNode = node;
				const childNodes = node.childNodes || [];
				if (childNodes.length === 1 && childNodes[0].type === NodeType.Element && childNodes[0].tagName === 'img') {
					imageNode = childNodes[0];
					return true;
				}
				return -1 !== (node.childNodes || []).findIndex(childNode => isSingleImage(childNode));
			}
			if (node.type === NodeType.Document || node.type === NodeType.Element) {
				return -1 !== (node.childNodes || []).findIndex(childNode => isSingleImage(childNode));
			}
			return false;
		};

		if (isSingleImage(event.data.node)) {
			bodyNode!.attributes = {
				...(bodyNode!.attributes || {}),
				style: (bodyNode!.attributes?.style || '') + ';margin:0;padding:0;'
			};
			imageNode!.attributes = {
				...(imageNode!.attributes || {}),
				style: (imageNode!.attributes?.style || '') + `;width:${width}px;height:unset;`
			};
		}
	});
};

export const reCalcTimeStamp = (events: Array<WebEventWithTime>) => {
	events.forEach(event => event.originalTimestamp = event.timestamp);
	let totalSkipTimes = 0;
	for(let i = 0;i < events.length; i++){
		if(i > 0 && (events[i].timestamp - events[i-1].timestamp) > 5*1000){
			totalSkipTimes = events[i].timestamp - events[i-1].timestamp - 5*1000;
			for(let j = i;j<events.length;j++){
				events[j].timestamp = events[j].timestamp - totalSkipTimes;
			}
		}
	}
};


const shouldReplaceImageSrc = (sources: Array<WebSource>, meta?: SegmentMeta) => {
	if (!meta) {
		return false;
	}
	return (sources.find(source => {
		if (source.source !== meta.source) {
			return false;
		}
		if (source.version) {
			if (source.version !== meta.version) {
				return false;
			}
		} else if (meta.version != null) {
			return false;
		}

		return true;
	}) || { should: false }).should;
};
const isImage = (src: string): boolean => {
	if (!src) {
		return false;
	}
	return [ '.png', '.jpg', '.jpeg', '.gif', '.webp', '.apng' ].findIndex(ext => src.endsWith(ext)) !== -1;
};
const URL_IN_CSS_REF = /url\((?:'([^']*)'|"([^"]*)"|([^)]*))\)/gm;
const RELATIVE_PATH = /^(?!www\.|(?:http|ftp)s?:\/\/|[A-Za-z]:\\|\/\/).*/;
const DATA_URI = /^(data:)([\w\/\+\-]+);(charset=[\w-]+|base64).*,(.*)/i;
const getCssTextReplacement = (cssText: string, meta: SegmentMeta, endpoint: string): string => {
	return cssText.replace(URL_IN_CSS_REF, (origin, path1, path2, path3) => {
		const filePath = path1 || path2 || path3;
		if (!filePath) {
			return origin;
		}
		if (!isImage(filePath) || DATA_URI.test(filePath)) {
			return `url('${filePath}')`;
		}
		return `url(${getImageSrcReplacement(filePath, meta, endpoint)})`;
	});
};
const getImageSrcReplacement = (src: string, meta: SegmentMeta, endpoint: string): string => {
	const url = new URL(src);
	const ossUrl = `/${meta.source || '$$X-FORCE$$'}/${meta.version || '$$SHIELD$$'}${url.pathname}`;

	return getSignatureUrl(ossUrl,endpoint);
};

const replaceImageSrcByFullSnapshot = (node: SerializedNodeWithId, meta: SegmentMeta, endpoint: string) => {
	if(node.type == NodeType.Document){
		(node.childNodes || []).forEach(childNode => replaceImageSrc(childNode, meta, endpoint));
	}
};

const replaceImageSrc = (node: SerializedNodeWithId, meta: SegmentMeta, endpoint: string) => {
	if (node.type !== NodeType.Element) {
		return;
	}

	const tagName = (node.tagName || '').toLowerCase();
	if ('img' === tagName) {
		const src = node.attributes?.src as string;
		if (!src || !isImage(src)) {
			// 没有指定, 或者已经是base64类型
			return;
		}
		try {
			node.attributes.src = getImageSrcReplacement(src, meta, endpoint);
			node.attributes.alt = `Original image source is [${src}]`;
		} catch {
			// do nothing
		}
	} else if ('link' === tagName || 'style' === tagName) {
		// 样式表
		if (node.attributes && node.attributes._cssText) {
			node.attributes._rr_cssText = node.attributes._cssText;
			node.attributes._cssText = getCssTextReplacement(node.attributes._cssText as string, meta, endpoint);
		}
	} else {
		(node.childNodes || []).forEach(childNode => replaceImageSrc(childNode, meta, endpoint));
	}
};

const getRRwebContents = async (rrwebFiles: ObjectMeta[],check: boolean,ossToken?: OssStsToken | null):Promise<Array<FileContent>> => {
	let contents = await fetchContentData(rrwebFiles,ossToken);
	const allContents = (contents || [])
		.filter(x => x != null && x.content != null);// && x.content.length !== 0去掉，归档后得到的是null，发现部分rrweb是[]空数组
	if(allContents.length !== rrwebFiles.length){
		if(!check){
			let tips = document.getElementsByClassName('tips');
			tips.item(0)!.innerHTML = '该视频文件已归档，正在解冻中,请耐心等待1分钟左右...';
			const names = rrwebFiles.filter(x => x.storageClass == 'Archive')
				.map((x) => x.name );

			await restoreArchiveFiles(names);
			await sleep(60000);
		}else{
			await sleep(5000);
		}
		const restoredNames = allContents.map((content) => content.meta.name);
		rrwebFiles = rrwebFiles.filter(x => !restoredNames.includes(x.name));
		contents = allContents.concat(await getRRwebContents(rrwebFiles,true,ossToken));
	}
	return contents;
};
//增加 account/ossToken，下载功能传参
export const initData = async (data: TraceData, account?: Account | null,ossToken?: OssStsToken | null): Promise<Details> => {
	if (!data || !data.traceNo) {
		// 没有数据
		return { loaded: true };
	}
	if (!account) {
		account = getAccount();
	}

	//let filePrefix = `${account.accountName}/${data.traceNo}`;
	//rrweb & meta
	let metaFilePrefix = `meta/${account.accountName}/${data.traceNo}`;
	let rrwebFilePrefix = `rrweb/${account.accountName}/${data.traceNo}`;
	//const { objects: files = [] } = await listFiles(filePrefix, '', 1000);
	const metafiles:ObjectMeta[]= await listFiles(metaFilePrefix, '', 1000,ossToken);
	const rrwebfiles:ObjectMeta[] = await listFiles(rrwebFilePrefix, '', 1000,ossToken);
	let allFiles = metafiles ? metafiles.concat(rrwebfiles) : [];
	if(allFiles.length === 0){
		let filePrefix = `${account.accountName}/${data.traceNo}`;
		const files:ObjectMeta[] = await listFiles(filePrefix, '', 1000,ossToken);
        let listfiles=files ? files:[];
		if(listfiles.length === 0){
			// 没有数据
			return { loaded: true };
		}
		allFiles = listfiles;
	}
	const ignoreFiles = allFiles
		.filter(meta => !meta.name.endsWith('.json') && !meta.name.endsWith('.rrweb'))
		.map(meta => meta.name);
	if (ignoreFiles.length !== 0) {
		console.warn('Files ignored.', ignoreFiles);
	}

	const metas = await fetchMetaData(allFiles,ossToken);
	const metaMap = metas.reduce((map, meta) => {
		map.set(meta.content.uuid, meta);
		return map;
	}, new Map<String, FileMeta>());
	const sources = metas.reduce((all, meta) => {
		const source = (meta.content.source || '').trim();
		const traceNo=(meta.content.traceNo || '').trim();
		if (source) {
			all.push({ source, version: meta.content.version, should: false ,traceNo});
		}
		return all;
	}, [] as Array<WebSource>);
	const imageSrcReplacement = {
		endpoint: ossToken?.ossEndpoint || '',
		sources
	};
	if (sources.length > 0) {
		try{
			const collectedSources = await askSourceCollected(sources);
			imageSrcReplacement.sources = collectedSources || [];
		}catch{
			console.log('askSourceCollected error')
		}
	}

	let contents = await getRRwebContents(allFiles.filter(file => file.name.endsWith('.rrweb')),false,ossToken);

	const stepTimeOffsets = new Map<UUID, OffsetInMillisecond>();
	let startTime = 0;
	const events = (contents || [])
		.filter(x => x != null && x.content != null && x.content.length !== 0)
		.reduce((all: Array<WebEventWithTime>, content: FileContent, index: number) => {
			if (index === 0) {
				// 记录起始时间
				startTime = content.content![0]!.timestamp;
			}
			// 每一个content都有一个uuid, 对照到meta里面的uuid, 然后找到记录的step
			// 如果step被记录, 则记录这个content的第一帧的时间作为偏移量
			const uuid = content.uuid;
			const meta = metaMap.get(uuid)?.content;
			const currentStep = meta?.currentStep;
			if (currentStep && !stepTimeOffsets.has(currentStep)) {
				stepTimeOffsets.set(currentStep, content.content![0].timestamp - startTime);
			}
			const events = (content.content || []);
			// 是否要替换image src
			if (shouldReplaceImageSrc(imageSrcReplacement.sources, meta)) {
				events.forEach(event => {
					if (event.type === WebEventType.FullSnapshot) {
						// 一般节点
						replaceImageSrcByFullSnapshot(event.data.node, meta!, imageSrcReplacement.endpoint);
					} else if (event.type === WebEventType.IncrementalSnapshot && event.data.source === IncrementalEventType.Mutation) {
						// mutation的adds和attributes
						(event.data.adds || []).forEach(add => {
							replaceImageSrc(add.node, meta!, imageSrcReplacement.endpoint);
						});
						(event.data.attributes || []).forEach(attr => {
							const src = attr.attributes.src;
							if (src && isImage(src)) {
								attr.attributes.src = getImageSrcReplacement(src, meta!, imageSrcReplacement.endpoint);
								attr.attributes.alt = `Original image source is [${src}]`;
							}
						});
					} else if (event.type === WebEventType.IncrementalSnapshot && event.data.source === IncrementalEventType.StyleSheetRule) {
						// 样式表
						(event.data.adds || []).forEach(add => {
							if (add.rule) {
								(add as any).rr_rule = add.rule;
								add.rule = getCssTextReplacement(add.rule, meta!, imageSrcReplacement.endpoint);
							}
						});
					}
				});
			}
			return [ ...all, ...events ];
		}, [] as Array<WebEventWithTime>);

	forceFixHeightForMobileReplay(events);
	sort(events);
	fixSimpleImageFrames(events);
	reCalcTimeStamp(events);

	return {
		stepTimeOffsets: stepTimeOffsets,
		events,
		metas,
		contents,
		loaded: true
	};
};

export const useData = (traceId: string,createAt: string) => {
	const [ traceData, setTraceData ] = useState<TraceData | null>(null);
	const [ details, setDetails ] = useState<Details>({ loaded: false });
	const [ account, setAccount ] = useState<Account | null>(null);
	const [ ossToken, setOssToken ] = useState<OssStsToken | null>(null);

	useEffect(() => {
		(async () => {
			const traceData = await initTraceData(traceId,createAt);
			setTraceData(traceData);
			sessionStorage.setItem('maskRules',''+traceData?.maskRules);
			if (traceData && traceData.traceNo) {
				setAccount({accountName:traceData.createdByAccountName, isAuthorized:true});
				const ossToken = await askOssStsToken(traceData?.tenantId);
				const details = await initData(traceData,{accountName:traceData.createdByAccountName, isAuthorized:true},ossToken);
				setDetails(details);
				setOssToken(ossToken);
			}
		})();
	}, [ traceId ]);

	return { trace: traceData, details, account, ossToken };
};

// useEffect(() => {
// 	import('./events').then(({ default: defaultEvents }) => {
// 		setData({ traceNo: 'rr#xyz' } as any);
// 		setVideos({
// 			all: [ 'abc.rrweb' ],
// 			allContents: defaultEvents,
// 			currentIndex: 0,
// 			totalDuration: 10,
// 			durations: [ 10 ],
// 			loaded: true
// 		});
// 	});
// }, [ 0 ]);