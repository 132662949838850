export default {
	TRACE: '/trace',
	TRACE_QUERY: '/trace/query',
	TRACE_VIEW: '/trace/view/:traceId/:createAt',
	TRACE_QUALITY_INSPECTION_QUERY: '/trace/qualityInspection/:taskName',
	TRACE_QUALITY_INSPECTION_VIEW: '/trace/qualityInspectionView/:traceId/:createAt/:taskName',
	TRACE_REPORT_MONTH:'/trace-report/reportMonth',
	TRACE_REPORT_DAY:'/trace-report/reportDay',

	FULL_TRACE: '/full-trace',
	FULL_TRACE_QUERY: '/full-trace/query',
	FULL_TRACE_DETAIL: '/full-trace/detail/:traceId/:createAt',

	AUDIT: '/audit',
	AUDIT_VIEW: '/audit/view',

	ARCHIVE: '/archive',
	ARCHIVE_QUERY: '/archive/query',

	ACCOUNT: '/account',
	ACCOUNT_QUERY: '/account/query',
	ACCOUNT_EDIT: '/account/audit',
	ACCOUNT_MODIFY: '/account/modify/:accountName/:role/:id/:tenantId',

	GLUTTON_USER:'/user',
	GLUTTON_USER_QUERY:'/user/query',
	GLUTTON_USER_EDIT:'/user/edit',

	MASK: '/mask',
	MASK_QUERY: '/mask/query',
	MASK_EDIT: '/mask/audit',

	SOURCE_CONFIG: '/sourceConfig',
	SOURCE_CONFIG_QUERY: '/sourceConfig/query',
	SOURCE_CONFIG_EDIT: '/sourceConfig/audit',

	OPERATION_LOG:'/operation-record/query',
    SYSTEM_OPERATION_LOG:'/system-operation/query',

	RECORD_TRACE_QUERY:'/record/trace',
	RECORD_BLOCKCHAIN_QUERY:'/record/blockchain',

	TRACE_DOWNLOAD_QUERY:'/dowload/query',

	QUALITY_INSPECTION:'/qualityInspection',
    QUALITY_INSPECTION_QUERY:'/qualityInspection/query/:taskName',
	QUALITY_INSPECTION_ADD:'/qualityInspection/add/:traceNo/:taskName/:createAt',

	QUALITY_INSPECTION_TASK:'/qualityInspectionTask',
	QUALITY_INSPECTION_TASK_QUERY:'/qualityInspectionTask/query',
	QUALITY_INSPECTION_TASK_ADD:'/qualityInspectionTask/add',
	QUALITY_INSPECTION_TASK_MODIFY:'/qualityInspectionTask/modify/:taskName',
	QUALITY_INSPECTION_TASK_VIEW:'/qualityInspectionTask/view/:taskName',


    TRACE_INSPECTION:'/traceInspection/query',

    TRACE_INSPECTION_REPORT:'/traceInspectionReport/query',

	DEMO: '/demo',
};