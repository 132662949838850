import OSS, {ObjectMeta} from 'ali-oss';
import { doGet } from './ajax';
import {AWSError, S3} from "aws-sdk";
import JsZip from 'jszip';
let AWS = require('aws-sdk');
let saveAs = require('file-saver');
let ObsClient = require('esdk-obs-browserjs');

export interface OssStsToken {
	ossEndpoint: string;
	ossRegion: string;
	ossBucket: string;
	accessKeyId: string;
	accessKeySecret: string;
	securityToken: string;
	proxy?: string;//国任下载服务无法访问oss，使用代理
}

// this timeout should less than ali-oss token expired time few seconds
const stsTokenTimout: number = 60 * 30 - 20;
let stsTokenRetrieveTime: number = -1;
let stsToken: OssStsToken | null = null;

export const askOssStsToken = (tenantId?:number): Promise<OssStsToken> => {
	return new Promise(async (resolve, reject) => {
		try {
			if (stsTokenRetrieveTime === -1 || ((Date.now() - stsTokenRetrieveTime) > stsTokenTimout * 1000)) {
				const url = tenantId ? '/api/oss/ask?tenantId='+tenantId : '/api/oss/ask';
				const response = await doGet(url);
				const currentTime = Date.now();
				stsToken = response.body;
				stsTokenRetrieveTime = currentTime;
			}
			resolve(stsToken!);
		} catch (e) {
			reject(e);
		}
	});
};

const createOssClient = (stsToken: OssStsToken) => {
	return new OSS({
		// secure: true,
        endpoint: window.location.href.indexOf('headlessPlayer') != -1 ? stsToken.proxy : (process.env.REACT_APP_OSS_ENDPOINT || stsToken.proxy || undefined),
		// region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
		region: stsToken.ossRegion,
		// 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
		accessKeyId: stsToken.accessKeyId,
		accessKeySecret: stsToken.accessKeySecret,
		stsToken: stsToken.securityToken,
		bucket: stsToken.ossBucket
	});
};

const createS3Client = (stsToken: OssStsToken) => {
	let s3 = new AWS.S3({apiVersion: '2006-03-01'});
	s3.endpoint = "https://s3.cn-east-2.jdcloud-oss.com";
	s3.config.update({
		endpoint: process.env.REACT_APP_OSS_ENDPOINT || undefined,
		accessKeyId: stsToken.accessKeyId,
		secretAccessKey: stsToken.accessKeySecret,
		region:stsToken.ossRegion,
		sessionToken:stsToken.securityToken,
		bucket:stsToken.ossBucket,
		signatureVersion: "v4"
	});
	return s3;
};

const createObsClient = (stsToken: OssStsToken) => {
	return new ObsClient({
		access_key_id: stsToken.accessKeyId,
		secret_access_key: stsToken.accessKeySecret,
		security_token: stsToken.securityToken,
		server : 'https://'.concat(stsToken.ossEndpoint)
	});
};

export const getFileDownloadUrl = async (fileName: string,ossToken?: OssStsToken | null, actions?: string): Promise<string> => {
	let stsToken;
	if(ossToken){
		stsToken = ossToken;
	}else{
		stsToken = await askOssStsToken();
	}
	if(stsToken.ossEndpoint.endsWith('aliyuncs.com')) {
		const client = createOssClient(stsToken);
		return client.signatureUrl(fileName, {
			expires: 3600,
			process: actions
			// response: {
			// 	'content-disposition': `attachment; filename=${encodeURIComponent('test.webm')}`
			// }
		});
	}else if(stsToken.ossEndpoint.endsWith('myhuaweicloud.com')){
		const client = createObsClient(stsToken);
		const res = client.createV2SignedUrlSync({Method : 'GET', Bucket : ossToken?.ossBucket, Key: fileName});
		return res.SignedUrl;
	}else{
		const client = createS3Client(stsToken);
		let params = {Bucket: ossToken?.ossBucket, Key: fileName};
		return Promise.resolve(client.getSignedUrl('getObject',params));
	}
};

export const restoreArchiveFiles = async (fileNames: string[]): Promise<void> => {
	const stsToken = await askOssStsToken();
	if(stsToken.ossEndpoint.endsWith('aliyuncs.com')){
		const client = createOssClient(stsToken);
		fileNames.forEach(fileName => client.restore(fileName).catch(err => {
			console.log(err);
		}));
	}else if(stsToken.ossEndpoint.endsWith('myhuaweicloud.com')){
		//TODO
		const client = createObsClient(stsToken);
		fileNames.forEach(fileName => {
			let params = {
				Bucket : stsToken.ossBucket,
				Key : fileName,
				Days : 1,
				Tier : client.enums.RestoreTierExpedited
			};
			client.restoreObject(params,function (err: any, result: any) {
				console.log(err);
			});
		});
	}else{
		const client = createS3Client(stsToken);
		fileNames.forEach(fileName => {
			let params = {
				Bucket: stsToken.ossBucket,
				Key: fileName,
				RestoreRequest: {
					Days: 1,
					GlacierJobParameters: {
						Tier: "Expedited"
					}
				}
			};
			client.restoreObject(params,function (err: AWSError, data: S3.Types.RestoreObjectOutput) {
				console.log(err);
			});
		});
	}
};

export { OSS };

export const listFiles = async (locationPrefix: string, delimiter?: string, max?: number | string,ossToken?: OssStsToken|null): Promise<ObjectMeta[]> => {
	let stsToken;
	if(ossToken){
		stsToken = ossToken;
	}else{
		stsToken = await askOssStsToken();
	}
	if(stsToken.ossEndpoint.endsWith('aliyuncs.com')){
		const client = createOssClient(stsToken);
		let options: OSS.ListObjectsQuery = { 'max-keys': 100 };
		options.prefix = locationPrefix;
		options.delimiter = delimiter;
		if (max) {
			options["max-keys"] = max;
		}
		let result:OSS.ListObjectResult = await client.list(options, {});
		return Promise.resolve(result.objects);
	}else if(stsToken.ossEndpoint.endsWith('myhuaweicloud.com')){
		const client = createObsClient(stsToken);
		let options = {
			Bucket : stsToken.ossBucket,
			MaxKeys : max ? max : 100,
			Prefix : locationPrefix
		};
		let results = await client.listObjects(options);
		let contents = results.InterfaceResult.Contents;
		let metas:ObjectMeta[]=[];
		for(let i = 0;i< contents.length;i++){
			metas.push({
				name: contents[i].Key,
				lastModified: contents[i].LastModified,
				etag: contents[i].ETag,
				type: 'Normal',
				size: contents[i].Size,
				storageClass:contents[i].StorageClass == 'STANDARD' ? 'Standard' : 'Archive',
				owner:{id: contents[i].Owner.ID,displayName: contents[i].Owner.DisplayName}
			});
		}
		return Promise.resolve(metas);
	}else{
		const client = createS3Client(stsToken);
		let params = {
			Bucket: stsToken.ossBucket,
			MaxKeys: max ? max : 100,
			Prefix:locationPrefix,
			Delimiter:''
		};
		let metas:ObjectMeta[]=[];
		let contents = await client.listObjects(params).promise().then((sss:S3.Types.ListObjectsOutput)=>{
			return Promise.resolve(sss.Contents);
		});
		for(let i = 0;i< contents.length;i++){
			metas.push({
				// @ts-ignore
				name: contents[i].Key,
				// @ts-ignore
				lastModified: contents[i].LastModified,
				// @ts-ignore
				etag: contents[i].ETag,
				type: 'Normal',
				// @ts-ignore
				size: contents[i].Size,
				storageClass:contents[i].StorageClass == 'STANDARD' ? 'Standard' : 'Archive',
				// @ts-ignore
				owner:{id: contents[i].Owner.ID,displayName: contents[i].Owner.DisplayName}
			});
		}
		return Promise.resolve(metas);
	}

};

export const downloadFile = async (locationPrefix: string) => {
	let stsToken = await askOssStsToken();
	if(stsToken.ossEndpoint.endsWith('aliyuncs.com')){
		const client = createOssClient(stsToken);
		const url = await client.signatureUrl(locationPrefix, {method: 'GET'});
		window.location.href = url;
	}else if(stsToken.ossEndpoint.endsWith('myhuaweicloud.com')){
		//TODO
		const client = createObsClient(stsToken);
		const res = client.createV2SignedUrlSync({Method : 'GET', Bucket : stsToken?.ossBucket, Key: locationPrefix});
		window.location.href = res.SignedUrl;
	}else{
		const client = createS3Client(stsToken);
		let params = {Bucket: stsToken.ossBucket, Key: locationPrefix};
		const url = await client.getSignedUrlPromise('getObject',params);
		window.location.href = url;
	}
};

export const batchDownloadFile = async (locationPrefixs: string[]) => {
	let stsToken = await askOssStsToken();
	const client = createOssClient(stsToken);
	let zip = new JsZip();
	for(let i = 0;i<locationPrefixs.length;i++){
		const res = await client.get(locationPrefixs[i]);
		//let folder = zip.folder(locationPrefixs[i].substring(0,locationPrefixs[i].lastIndexOf('\/')));
		//folder!.file(locationPrefixs[i].substring(locationPrefixs[i].lastIndexOf('\/')+1,locationPrefixs[i].length),res.content);
		zip.file(res.content);
	}
	zip.generateAsync({type:'blob'}).then(content =>{
		saveAs(content,'批量下载结果');
	});
};

export const getSignatureUrl = (ossUrl: string,endpoint: string): string => {
	if(stsToken){
		if(stsToken.ossEndpoint.endsWith('aliyuncs.com')) {
			const client = createOssClient(stsToken);
			return client.signatureUrl(ossUrl);
		}else if(stsToken.ossEndpoint.endsWith('myhuaweicloud.com')){
			const client = createObsClient(stsToken);
			const res = client.createV2SignedUrlSync({Method : 'GET', Bucket : stsToken?.ossBucket, Key: ossUrl});
			return res.SignedUrl;
		}else{
			const client = createS3Client(stsToken);
			let params = {Bucket: stsToken?.ossBucket, Key: ossUrl};
			return client.getSignedUrl('getObject',params);
		}
	}
	return `https://${endpoint}${ossUrl}`;
};