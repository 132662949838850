import Account from '../service/account-model';
import { doGet } from '../service/ajax';
import { getAccount, clearAccount } from '../service/storage';

export const profile = async (ticket: string): Promise<Account> => {
	const resp = await doGet(`/api/csms/account/profile?ticket=${ticket}`);
    sessionStorage.setItem("glutton-account-nickname",JSON.stringify(resp?.body?.nickName));
    sessionStorage.setItem("glutton-account-role",JSON.stringify(resp?.body?.roles ? resp.body.roles : []));
    sessionStorage.setItem("glutton-account-authTenants",JSON.stringify(resp?.body?.authTenants ? resp.body.authTenants : []));
    sessionStorage.setItem("glutton-account-tenantId", JSON.stringify(resp?.body?.tenantId ? resp?.body?.tenantId : null));
    window.location.replace(window.location.href.split(window.location.host)[0]+window.location.host);
    return getAccount();
};

export const validatePublicCloudToken = async (onSigned: (account: Account) => void) => {
    const localHref = window.location.href;
    /*if(window.location.host.indexOf('localhost') != -1){
        const account = await profile('RUJBTy54dWhhaToxMDVhMjljMmVlMTE0YmFjYTczMTRkZDEzZmNlYTU2MDoxNjg1MDg5OTk3NjE5');
        await onSigned(account);
        return;
    }*/
    if (localHref.split('?').length > 1 && localHref.indexOf('fromCsms=') > 0) {
        window.addEventListener('message',async (e)=>{
            const data = e.data;
            if(data){
                const account = await profile(data);
                await onSigned(account);
            }
        });
    } else {
        csmsLogin();
    }
};

export const csmsLogin = () => {
    clearAccount();
    window.location.href = process.env.REACT_APP_CAS_LOGIN_URL+'';
};

export const csmsLogout = () => {
    clearAccount();
	window.location.href = process.env.REACT_APP_CAS_LOGIN_URL+'';
};
