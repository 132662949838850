import React, { useReducer, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSecurity } from '../hooks/security';
import { isCCICDisplay } from '../utils';
import {
    Button,
    ButtonBar,
    ButtonBarAlignType,
    ButtonType,
    DateTimeRange,
    FormSectionGapVertical,
    PanelSeparator,
    QueryPanel,
    QueryResultColumns,
    QueryResultPanel,
    QueryResultRow,
    Text,
    WorkAreaTitle,
    CheckBox
} from '../component';
import Path from '../path';
// import { doGet } from '../service/ajax';
import { endOfDate, formatFull, startOfDate } from '../service/date';
import {
    findTraceDataPage,
    findTraceInspectionDataPage,
    TraceCriteria,
    TraceDataPage,
    findTenantDynamicQueryCriteria,
    DynamicCriteria,
} from '../service/trace-service';
import { useShares } from '../hooks/shares';
import { doExport, doPost,uploadExcelFile} from "../service/ajax";
import {DropdownOption} from "../component/dropdown";
import Dropdown from "../component/dropdown";

const TraceQuery = styled.div`
	display: flex;
	flex-direction: column;
`;

const ButtonWrapper = styled.div`
	width: max-content;
	text-align: center;
	> *:last-child {
		display: inline-block;
		margin-left: var(--gap-thin);
	}
`;

const ButtonInputFile = styled.div`
    display: inline-block;
    width: 100px;
    height: 32px;
    position: relative;
    top: 11px;
    border-radius: 4px;
    overflow: hidden;
    >input{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999999;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
`;
type InputCriteria = Exclude<keyof TraceCriteria, 'pageSize' | 'pageNumber'>

export default (props: { taskName?: string}) => {
    const types = new Array<DropdownOption>({value:'',label:'请选择'},{value:'1',label:'databus'},{value:'2',label:'excel'});
    const resultStatuss = new Array<DropdownOption>({value:'',label:'请选择'},{value:'1',label:'成功'},{value:'2',label:'失败'});
    const { taskName } = props;
    const shares = useShares();
    const security = useSecurity();
    const now = new Date();
    const [ isFlag,setIsFlag ] = useState(false);
    const [ isDateReset, setisDateReset ] = useState(false);

    const [ allcheckbox ,  setAllcheckbox  ]:any = useState(false);
    const [ , forceUpdate ] = useReducer(x => x + 1, 0);
    const [ data, setData ] = useState<TraceDataPage | null>(null);
    const [ searched, setSearched ] = useState(false);
    const [fileName, setFileName]:any = useState('文件名');
    const [ dynamicCriteria, setDynamicCriteria ] = useState<Array<DynamicCriteria> | null>(null);
    const [ criteria ] = useState<TraceCriteria>(shares.reachout('__trace_query_criteria_keeped') || {
        createFrom: formatFull(startOfDate(now)),
        createTo: formatFull(endOfDate(now)),
        traceNo: '',
        productName: '',
        policyNo: '',
        orderNo: '',
        policyHolderName: '',
        policyHolderIdNo: '',
        policyHolderMobile: '',
        source: '',
        quoteNo: '',
        payNo: '',
        payOrderNo: '',
        uniqueNo1: '',
        uniqueNo2: '',
        uniqueNo3: '',
        uniqueNo4: '',
        uniqueNo5: '',
        uniqueNo6: '',
        uniqueNo7: '',
        uniqueNo8: '',
        uniqueNo9: '',
        uniqueNo10: '',

        allChecked:false,
        traceDataList:[]


    });
    const file =useState({} || null);

        var columns: QueryResultColumns = [
            { label: '#', width: '30px', name: 'seq' },
            { label: '处理时间', width: '140px', name: 'createTime' },
            { label: '回溯号', width: '150px', name: 'traceNo' },
            {
                label: <React.Fragment>
                    <div>报价单号</div>
                    <div>保单号</div>
                    <div>订单号</div>
                    <div>付款号</div>
                    <div>支付订单号</div>
                </React.Fragment>, width: '180px', name: [ 'quoteNo', 'policyNo', 'orderNo','payNo', 'payOrderNo' ]
            },
            {
                label: <React.Fragment>
                    <div>投保人</div>
                    <div>投保人证件号码</div>
                    <div>投保人手机号码</div>
                </React.Fragment>,
                width: '160px',
                name: [ 'policyHolderName', 'policyHolderIdNo', 'policyHolderMobile' ]
            },
            { label: '步骤', width: '90px', name: 'steps' },
            { label: '来源', width: '90px', name: 'source' },
            { label: '类型', width: '90px', name: 'type' },
            { label: '结果', width: '90px', name: 'resultStatus' },
            { label: '描述', width: '90px', name: 'remark' },
            /*{ label: '', width: '60px', name: 'actions' }*/
        ];

    useEffect(() => {
        (async () => {
            const dynamicCriterias = await findTenantDynamicQueryCriteria();
            setDynamicCriteria(dynamicCriterias);
            /*if(window.location.href.indexOf('ebaocloud.com.cn') > 0 && security.accountName == 'ydl'){
                setIsFlag(true);
            }*/
        })();
    }, []);
    const [ currentCriteria, setCurrentCriteria ] = useState<TraceCriteria | null>(null);

    const onChanged = (propName: InputCriteria) => (event: React.ChangeEvent) => {
        // @ts-ignore
        criteria[propName] = (event.target as HTMLInputElement).value;
        forceUpdate();
    };
    const onFileChanged = (file:any) => {
        file= file.target .files[0];
        if(!file){

            alert('您上传的文件为空!');
            setFileName("文件名");
            return;
        }
        console.log(file)
        setFileName(file.name);
        const fileType =file.type;
        if("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"!=fileType ){
            alert('您上传文件格式不对,请重新选择!');
            return;
        }
        const fileSize = file.size > 30720
        if (fileSize) {
            alert('您上传的文件超过20K，请重新确认文件大小！');
            return
        }
        console.log(file);
        forceUpdate();
    };

    const onDateChanged = (startDate?: Date, endDate?: Date) => {
        criteria.createFrom = formatFull(startOfDate(startDate));
        criteria.createTo = formatFull(endOfDate(endDate));
        forceUpdate();
    };

    // doGet('https://saletrace-test.ccic-net.com.cn/saletrace/api/trace/list');
    const doSearch = async (pagination: TraceCriteria) => {
        // 设置当前查询
        setCurrentCriteria(pagination);
        const data = await findTraceInspectionDataPage(pagination);
        if(localStorage.getItem('allcheckbox')==='true'){
            data.rows?.forEach(item=>{
                // @ts-ignore
                item.checked=true;
                if(criteria.allChecked){
                    // @ts-ignore
                    criteria.traceDataList.forEach(i=>{
                        if(i.traceNo===item.traceNo){
                            item.checked=false;
                        }
                    })
                }
            })
        }else{
            data.rows?.forEach(item=>{
                // @ts-ignore
                item.checked=false;
                if(!criteria.allChecked){
                    // @ts-ignore
                    criteria.traceDataList.forEach(i=>{
                        if(i.traceNo===item.traceNo){
                            item.checked=true;
                        }
                    })
                }
            })
        }
        setData(data);
        setSearched(true);
    };
    const onSearchClicked = async (criteria: TraceCriteria) => {
        if(criteria.createFrom! > criteria.createTo!){
            alert('资料生成开始日期不得大于结束日期，请重新选择');
            return;
        }
        if(isCCICDisplay()){
            if(!criteria.type && !criteria.orderNo &&  !criteria.policyNo
                && !criteria.payNo && !criteria.quoteNo && !criteria.payOrderNo && !criteria.policyHolderName
                && !criteria.policyHolderIdNo && !criteria.policyHolderMobile && !criteria.source){
                alert('请至少录入一项查询条件');
                return;
            }
            if( criteria.policyHolderName){
                if(!criteria.type && !criteria.orderNo && !criteria.policyNo
                    && !criteria.payNo && !criteria.quoteNo && !criteria.payOrderNo
                    && !criteria.policyHolderIdNo && !criteria.policyHolderMobile && !criteria.source){
                    alert('投保人姓名需搭配其他查询条件使用');
                    return;
                }
            }
            const from = criteria.createFrom!.split("-"),
                to = criteria.createTo!.split("-"),
                year1 = parseInt(from[0]),
                month1 = parseInt(from[1]),
                year2 = parseInt(to[0]),
                month2 = parseInt(to[1]);
            //通过年,月差计算月份差
            //months = (year2 - year1) * 12 + (month2 - month1) + 1;
            if(year1 !== year2 || month1 !== month2){
                alert('仅支持一个月内的查询，请重新选择日期');
                return;
            }
        }

        localStorage.removeItem('allcheckbox');
        setAllcheckbox(false);
        // @ts-ignore
        criteria.traceDataList=[];
        shares.share('__trace_query_criteria_keeped', criteria);
        const pagination = {
            ...criteria,
            pageNumber: 1,
            pageSize: 10
        };
        await doSearch(pagination);
    };
    const onResetClicked = () => {
        shares.discard('__trace_query_criteria_keeped');
        // @ts-ignore
        Object.keys(criteria).filter((key) => key !== 'archiveStatuses').forEach((key) => criteria[key as InputCriteria] = '');
        criteria.createFrom = formatFull(startOfDate(now));
        criteria.createTo = formatFull(endOfDate(now));
        setisDateReset(true);
        let files:any = document.getElementById('file');
        if(files){
            window.location.reload();
        }
        forceUpdate();
    };
    const onPageChanged = async (pageNumber: number) => {
        const pagination = {
            ...currentCriteria,
            pageNumber
        };
        await doSearch(pagination);
    };

    const dataFound = data || { pageNumber: 1, pageSize: 10, totalPages: 1, totalItemCount: 0, rows: [] };
    // const onArchiveClicked = async (row: QueryResultRow) => {
    // 	await archiveTraceData(row.traceId);
    // 	row.archived = true;
    // 	forceUpdate();
    // };
    const rows = (dataFound.rows || []).map((row): QueryResultRow => {
        const data = row as QueryResultRow;
        const onChangeds = () => (event: React.ChangeEvent) => {
            // @ts-ignore
            data.checked = (event.target as HTMLInputElement).checked;
            if(criteria.allChecked){
                if(data.checked===false){
                    // @ts-ignore
                    criteria!.traceDataList!.push({
                        createTime:data.createTime ,
                        productName: data.productName,
                        traceNo: data.traceNo,
                        policyNo:'',
                        orderNo:'',
                        quoteNo:'',
                        payNo:'',
                        payOrderNo:''
                    });
                }else{
                    // @ts-ignore
                    criteria.traceDataList=criteria.traceDataList.filter(item=>item.traceNo!==data.traceNo);
                }
            }else{
                if(data.checked){
                    // @ts-ignore
                    criteria!.traceDataList!.push({
                        createTime:data.createTime ,
                        productName: data.productName,
                        traceNo: data.traceNo,
                        policyNo:'',
                        orderNo:'',
                        quoteNo:'',
                        payNo:'',
                        payOrderNo:''
                    });
                }else{
                    // @ts-ignore
                    criteria.traceDataList=criteria.traceDataList.filter(item=>item.traceNo!==data.traceNo);
                }
            }
            forceUpdate();
            // @ts-ignore
        };
        if(isFlag){
            data.checkbox=<CheckBox value={data.checked} onChange={onChangeds()}  />;
        }
        if(taskName){
            data.actions = <ButtonWrapper>
                <Button label="质检" type={ButtonType.LINK}
                        onClick={() => window.open(Path.TRACE_QUALITY_INSPECTION_VIEW
                            .replace(':traceId', `${row.traceId}`)
                            .replace(':createAt',`${row.createTime}`)
                            .replace(':taskName',taskName))}/>
            </ButtonWrapper>;
        }else{
            data.actions = <ButtonWrapper>
                <Button label="查看" type={ButtonType.LINK}
                        onClick={() => window.open(Path.TRACE_VIEW
                            .replace(':traceId', `${row.traceId}`)
                            .replace(':createAt',`${row.createTime}`))}/>
            </ButtonWrapper>;
        }
        return data;
    });
    const traceUpdate = () => {
        forceUpdate();
    };


    const onUploadClicked = async () =>{
        let files:any = document.getElementById('file');
         if(!files){
             alert("请先选择文件");
             return;
         }
         const formData = new FormData();
         formData.append("file", files.files[0]);
         uploadExcelFile('/api/ccic/full/trace/uploadExcel',formData);

    };

    // noinspection DuplicatedCode
    return <TraceQuery>
        <WorkAreaTitle title={taskName ? taskName : "回溯质检管理"}/>
        <QueryPanel>
            <DateTimeRange label="资料生成时间" columns={2} startDate={criteria.createFrom!} endDate={criteria.createTo!} isReset={isDateReset}
                           onChange={onDateChanged} update={traceUpdate} />
            <Dropdown label="类型" placeholder="请输入" value={criteria.type} options={types}  onChange={onChanged('type')}/>

            <Text label="保单号" placeholder="请输入" value={criteria.policyNo} onChange={onChanged('policyNo')}/>
            <Text label="报价单号" placeholder="请输入" value={criteria.quoteNo} onChange={onChanged('quoteNo')}/>
            <Text label="订单号" placeholder="请输入" value={criteria.orderNo} onChange={onChanged('orderNo')}/>
            <Text label="付款号" placeholder="请输入" value={criteria.payNo} onChange={onChanged('payNo')}/>
            <Text label="支付订单号" placeholder="请输入" value={criteria.payOrderNo} onChange={onChanged('payOrderNo')}/>
            <Text label="来源" placeholder="请输入" value={criteria.source} onChange={onChanged('source')}/>
            <Text label="投保人姓名" placeholder="请输入" value={criteria.policyHolderName}
                  onChange={onChanged('policyHolderName')}/>
            <Text label="投保人证件号码" placeholder="请输入" value={criteria.policyHolderIdNo}
                  onChange={onChanged('policyHolderIdNo')}/>
            <Text label="投保人手机号码" placeholder="请输入" value={criteria.policyHolderMobile}
                  onChange={onChanged('policyHolderMobile')}/>
            {dynamicCriteria?.map((dynamicCriteria, idx) => {
                // @ts-ignore
                return <Text key={`dynamicCriteria_${idx}`}
                             label={`${dynamicCriteria.displayName}`}
                             placeholder="请输入"
                             value={(criteria as any)[`uniqueNo${dynamicCriteria.columnSeq}`]}
                             onChange={onChanged(`uniqueNo${dynamicCriteria.columnSeq}` as any)}/>;
            })}
            <PanelSeparator columns={3}/>
            <ButtonBar align={ButtonBarAlignType.CENTER}>
                <Button label="查询" type={ButtonType.PRIMARY} onClick={() => onSearchClicked(criteria)}/>
                <Button label="重置" type={ButtonType.WAIVE} onClick={onResetClicked}/>
                <Button label="上传" type={ButtonType.PRIMARY} onClick={onUploadClicked}/>
                <ButtonInputFile>
                    <Button label="选择文件" type={ButtonType.PRIMARY}/>
                    <input type="file" id ="file" name="file" accept={".xls,.xlsx"}onChange={onFileChanged} />
                </ButtonInputFile>
                <span>{fileName}</span>



               {/* <Upload customRequest={uploadFile} showUploadList={false} beforeUpload={beforeUnload}>
                    <Button>上传</Button>
                </Upload>*/}



            </ButtonBar>
            <PanelSeparator columns={3}/>
        </QueryPanel>
        <FormSectionGapVertical/>
        <QueryResultPanel columns={columns} rows={rows} pagination={true} searched={searched}
                          pageCount={dataFound.totalPages} pageNumber={dataFound.pageNumber}
                          totalItemCount={dataFound.totalItemCount}
                          pageChange={onPageChanged}/>
    </TraceQuery>;
}