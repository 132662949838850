import { doExport, doGet, doPost } from './ajax';

export interface RecordCriteria {
	createFrom?: string;
	createTo?: string;
	tenantId?: string;//租户id
	productName?:string;//产品名称
	policyHolderName?:string;//投保人
	blockFlag?:string;
	pageNumber?: number;
	pageSize?: number;
}

export interface RecordDataPage {
	pageSize: number;
	pageNumber: number;
	totalPages: number;
	totalItemCount: number;
	rows?: Array<RecordData & { seq: number, checked?: number }>
}

export interface RecordBlockchainDataPage{
	pageSize:number;
	pageNumber:number;
	totalPages:number;
	totalItemCount:number;
	rows?:Array<blockChainData & {seq:number,checked?:number}>
}

export interface Account{
   	tenantId:number;
	accountName:string;
	name?:string;
	checked?:boolean | undefined;
	role?:string;
	roles?:Array<string>;

}

export interface AccountList{
	accounts?:Array<Account>;
}


//回溯服务记录
export interface RecordData {
	seq: number;
    traceId: string;
	traceNo: string;
	createTime: string;
	productName?: string;
	policyNo?: string;
	orderNo?: string;
	quoteNo?: string;
	payNo?: string;
	payOrderNo?: string;
}

//区块链记录
export interface blockChainData{
	seq: number;
    traceNo: string;//回溯码
    policyNo?: string;
    orderNo?: string;
	createTime?: string;
	chainContractNo?:string;
}

export const findRecordDataPage = async (criteria: RecordCriteria): Promise<RecordDataPage> => {
	const { pageNumber = 1, pageSize = 10, ...rest } = criteria;
	const response = await doPost(`/api/record/trace?pageNumber=${pageNumber}&pageSize=${pageSize}`, rest);
	const responseData = response.body || {
		pageNumber: 1,
		pageSize: 10,
		totalPages: 0,
		totalItemCount: 0,
		data: []
	};
	const { data, ...pages } = responseData;
	return {
		...pages,
		rows: data.map((row: any, index: number) => {
			row.seq = index + 1;
			return row;
		})
	};
};

export const findRecordBlockchainDataPage = async (criteria: RecordCriteria): Promise<RecordBlockchainDataPage> => {
	const { pageNumber = 1, pageSize = 10, ...rest } = criteria;
	const response = await doPost(`/api/record/blockchain?pageNumber=${pageNumber}&pageSize=${pageSize}`, rest);
	const responseData = response.body || {
		pageNumber: 1,
		pageSize: 10,
		totalPages: 0,
		totalItemCount: 0,
		data: []
	};
	const { data, ...pages } = responseData;
	return {
		...pages,
		rows: data.map((row: any, index: number) => {
			row.seq = index + 1;
			return row;
		})
	};
};


export const exportTraceRecordData =  (criteria: RecordCriteria) => {
	const { pageNumber = 1, pageSize = 10, ...rest } = criteria;
	doExport(`/api/record/export/trace`,rest);

};

export const exportBlockchainRecordData =  (criteria: RecordCriteria) => {
	const { pageNumber = 1, pageSize = 10, ...rest } = criteria;
	doExport(`/api/record/export/blockchain`,rest);

};

export const getTenantList = async ():Promise<AccountList> => {
	const response = await doGet("/api/record/tenant");
	var responseData = response.body||{data:[]};
	const data = responseData;
	return {
		accounts:data.map((account:any) =>{
			return account;
		})
	};
}





