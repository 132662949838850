import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlug} from '@fortawesome/free-solid-svg-icons';



const Footer = styled.div`
	display: flex;
	width: 100%;
	--footer-height: 50px;
	height: var(--footer-height);
	padding: 0 var(--side-margin);
	background-color: var(--footer-bg-color);
	color: var(--footer-color);
	z-index: var(--footer-z-index);
`;
const CopyRight = styled.div`
	line-height: var(--footer-height);
	opacity: var(--blurred-color-opacity);
`;
const CopyCenter = styled.div`
	opacity: var(--blurred-color-opacity);
	text-align: center;
	flex-grow: 50;  
	align-items: center !important;
	> div>span >a {
	    text-align: center;
	    padding-top: 5px;
	    font-size: 13px;
	    color: #999999;
		align-self: center;
	}
	> div>span  {
		 margin-top: 1px;
	    margin-left: 260px;
	    text-align: center;
	    align-self: center;
	    color: #999999;
	}
	> div>span >img{
	    vertical-align: middle;
	    text-align: center;
		align-self: center;
	}
	> div >p{
	    text-align: center;
	    margin-top: 5px;
	    margin-left: 260px;
	    font-size: 13px;
	    color: #999999;
	    line-height: 15px;
	}
`;
const Placeholder = styled.div`
	flex-grow: 1;
`;
const Builder = styled.div`
	display: flex;
	line-height: var(--footer-height);
	> svg {
		margin-right: var(--gap-thin);
		align-self: center;
	}
	> span {
		align-self: center;
	}
`;

export default () => {
    return <Footer>
        {/* <CopyRight>Copyright © 2022 eBaoTech Corporation. All rights reserved.</CopyRight>*/}
        <CopyCenter>
            <div>
                <p>eBaoCloud Copyright © {new Date().getFullYear()} eBaoTech Corporation All rights reserved.<br/></p>
                <span>
                    <img src="/police.png"></img>&nbsp;
                    <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002002950"
                          target="_blank">沪公网安备
                    31011002002950号</a>
                   /<a href="https://beian.miit.gov.cn" target="_blank">沪ICP备14035654号-2</a></span>
            </div>
        </CopyCenter>
        <Placeholder/>
        <Builder>
            <FontAwesomeIcon icon={faPlug}/>
            <span>Powered by eBaoCloud®</span>
        </Builder>
    </Footer>;
}
export interface imageHtml {
    image?: HTMLImageElement;
}
