import {faUser, faFileExport,faAngleDoubleLeft, faLink, faDownload,faPhotoVideo, faVideo,faHistory,faUserEdit,faEyeSlash,faCog,faStethoscope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Path from './path';
import { isAuthorized,isPlatformDisplay,isBillAdminDisplay,isAdminDisplay,isMEJ} from './utils';

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;
const fadeOut = keyframes`
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
`;
const rotateToRight = keyframes`
	from {
		transform: rotateY(0deg);
	}
	to {
		transform: rotateY(180deg);
	}
`;
const rotateToLeft = keyframes`
	from {
		transform: rotateY(180deg);
	}
	to {
		transform: rotateY(0deg);
	}
`;

const SideBar = styled.div`
	min-height: calc(var(--gap) * 4 + var(--gap-thinnest) * 3 + var(--side-bar-menu-height) * 4);
	min-width: var(--side-bar-width);
	width: var(--side-bar-width);
	background-color: var(--side-bar-bg-color);
	border-right: var(--side-bar-border);
	padding-top: var(--gap);
	padding-bottom: var(--gap);
	display: flex;
	flex-direction: column;
	transition: all 300ms ease-in-out;
	&[data-collapsed=true] {
		min-width: 60px;
		width: 60px;
		> div {
			> div {
				animation: 300ms ${fadeOut} ease-in-out forwards;
			}
			&:last-child > svg {
				animation: 300ms ${rotateToRight} ease-in-out forwards;
			}
		}
	}
`;
const FirstLevelMenu = styled.div`
	display: flex;
	align-items: center;
	height: var(--side-bar-menu-height);
	line-height: calc(var(--side-bar-menu-height) - 2px);
	font-size: var(--side-bar-menu-font-size);
	margin: 0 var(--gap-thin);
	padding: 0 var(--padding);
	border-radius: var(--radius);
	cursor: pointer;
	transition: all 300ms ease-in-out;
	border: 1px solid transparent;
	white-space: nowrap;
	overflow-x: hidden;
	background-color: transparent;
	color: var(--font-color);
	box-shadow: none;
	user-select: none;
	&:not(:last-child) {
		margin-bottom: var(--gap-thinnest);
	}
	&[data-active=true] {
		background-color: var(--side-bar-active-bg-color);
		color: var(--side-bar-active-color);
		border-color: var(--side-bar-active-border-color);
		box-shadow: none;
	}
	&:not([data-active=true]):hover {
		background-color: var(--side-bar-hover-bg-color);
		color: var(--side-bar-hover-color);
		border-color: var(--side-bar-hover-border-color);
		box-shadow: 0.5px 0.5px 0.5px 0.5px var(--side-bar-hover-box-shadow-color);
	}
	> svg {
		margin-right: var(--gap-thin);
		margin-left: -2px;
	}
	&:last-child > svg {
		animation: 300ms ${rotateToLeft} ease-in-out;
	}
	> div {
		animation: 300ms ${fadeIn} ease-in-out;
	}
`;

export default () => {
	const [ collapsed, setCollapsed ] = useState(window.location.href.indexOf('/qualityInspectionView') > 0 ? true : false);
	const history = useHistory();
	const location = useLocation();

	const onTraceClicked = () => history.push(Path.TRACE_QUERY);
	const onTraceFullClicked = () => history.push(Path.FULL_TRACE_QUERY);
	const onAuditClicked = () => history.push(Path.AUDIT);
	const onDemoClicked = () => history.push(Path.DEMO);
	const onTraceDownloadClicked = () => history.push(Path.TRACE_DOWNLOAD_QUERY);
	const onRecordTraceClicked = () => history.push(Path.RECORD_TRACE_QUERY);
	const onRecordBlockchainClicked = () => history.push(Path.RECORD_BLOCKCHAIN_QUERY);
    const onAccountCliked = () => history.push(Path.ACCOUNT_QUERY);
	const onOperationRecordCliked = () => history.push(Path.OPERATION_LOG);
	const onMaskCliked = () => history.push(Path.MASK_QUERY);
	const onGluttonUserCliked = () => history.push(Path.GLUTTON_USER);
	const onSourceConfigCliked = () => history.push(Path.SOURCE_CONFIG_QUERY);
	const onQualityInspectionClick = () => history.push(Path.QUALITY_INSPECTION);
	const onQualityInspectionTaskClick = () => history.push(Path.QUALITY_INSPECTION_TASK_QUERY);
	const onTraceReportMonthClick = () => history.push(Path.TRACE_REPORT_MONTH);
	const onTraceReportDayClick = () => history.push(Path.TRACE_REPORT_DAY);
    const onTraceInspectionBusinessClick = () => history.push(Path.TRACE_INSPECTION);
    const onSystemOperationRecordClick = () => history.push(Path.SYSTEM_OPERATION_LOG);
	const onToggleMenuClicked = () => {
		setCollapsed(!collapsed);
	};
	/*if(window.location.href.indexOf('/qualityInspection') > 0){
		onToggleMenuClicked();
	}*/

	const traceLabel = '销售回溯管理';
	const traceFullLabel = '全流程回溯管理';
	const auditLabel = '区块链验真';
	const recordLabelTrace  = '回溯服务记录';
	const recordLabelBlockchain  = '区块链验证记录';
	const gluttonUserLabel = '可回溯帐号配置';
	const accountLabel = 'CAS账户配置';
	const operationRecordLabel = '可回溯操作日志';
	const maskLabel = '脱敏配置';
	const demoLabel = '小试牛刀';
	const expandLabel = '展开菜单';
	const downloadLabel = '视频下载记录';
	const sourceConfigLabel = '版本管理配置';
	const qualityInspectionLabel = '质检报表';
	const qualityInspectionTaskLabel = '质检任务管理';
	const traceReportMonthLabel = '回溯信息月统计报表';
	const traceReportDayLabel = '回溯信息日统计报表';
    const traceInspectionBusinessLabel = '回溯质检管理';
    const systemOperationRecordLabel = '回溯审计操作记录';

	const onTrace = matchPath(location.pathname, { path: Path.TRACE }) != null;
	const onTraceFull = matchPath(location.pathname, { path: Path.FULL_TRACE_QUERY }) != null;
	const onAudit = matchPath(location.pathname, { path: Path.AUDIT }) != null;
	const onGluttonUser = matchPath(location.pathname, { path: Path.GLUTTON_USER }) != null;
	const onDemo = matchPath(location.pathname, { path: Path.DEMO }) != null;
	//const onTest = matchPath(location.pathname, { path: Path.TEST }) != null;
	const onRecordTrace = matchPath(location.pathname, { path: Path.RECORD_TRACE_QUERY }) != null;
	const onAccount = matchPath(location.pathname,{ path:Path.ACCOUNT}) != null;
	const onMask = matchPath(location.pathname,{ path:Path.MASK}) != null;
	const onSourceConfig = matchPath(location.pathname,{ path:Path.SOURCE_CONFIG}) != null;
	const onRecordBlockchain = matchPath(location.pathname, { path: Path.RECORD_BLOCKCHAIN_QUERY }) != null;
	const onTraceDownload = matchPath(location.pathname, { path: Path.TRACE_DOWNLOAD_QUERY }) != null;
	const onOperationRecord = matchPath(location.pathname,{ path:Path.OPERATION_LOG}) != null;
	const onQualityInspection = matchPath(location.pathname,{ path:Path.QUALITY_INSPECTION}) != null;
	const onQualityInspectionTask = matchPath(location.pathname,{ path:Path.QUALITY_INSPECTION_TASK}) != null;
	const onTraceReportMonth = matchPath(location.pathname,{ path:Path.TRACE_REPORT_MONTH}) != null;
	const onTraceReportDay = matchPath(location.pathname,{ path:Path.TRACE_REPORT_DAY}) != null;
    const onTraceInspectionBusiness = matchPath(location.pathname,{ path:Path.TRACE_INSPECTION}) != null;
    const  onSystemOperationRecord = matchPath(location.pathname,{ path:Path.SYSTEM_OPERATION_LOG}) != null;

    if(!isAuthorized()){
    	return <span/>;
	}
	return <SideBar data-collapsed={collapsed}>
        {!isBillAdminDisplay()
        && <FirstLevelMenu onClick={onTraceClicked} title={collapsed ? traceLabel : undefined} data-active={onTrace}>
			<FontAwesomeIcon icon={faPhotoVideo}/>
			<div>{traceLabel}</div>
		</FirstLevelMenu>}

		{isAdminDisplay()
			? <FirstLevelMenu onClick={onTraceFullClicked} title={collapsed ? traceFullLabel : undefined} data-active={onTraceFull}>
				<FontAwesomeIcon icon={faPhotoVideo}/>
				<div>{traceFullLabel}</div>
			  </FirstLevelMenu>
			: null
		}

        {/*{!isBillAdminDisplay()
        && <FirstLevelMenu onClick={onAuditClicked} title={collapsed ? auditLabel : undefined} data-active={onAudit}>
			<FontAwesomeIcon icon={faLink}/>
			<div>{auditLabel}</div>
		</FirstLevelMenu>}*/}

        {!isBillAdminDisplay()
        && <FirstLevelMenu onClick={onTraceDownloadClicked} title={collapsed ? downloadLabel : undefined} data-active={onTraceDownload}>
			<FontAwesomeIcon icon={faDownload}/>
			<div>{downloadLabel}</div>
		</FirstLevelMenu>}

		{isAdminDisplay()
			? <FirstLevelMenu onClick={onGluttonUserCliked} title={collapsed ? gluttonUserLabel : undefined}
							  data-active={onGluttonUser}>
				<FontAwesomeIcon icon={faUserEdit}/>
				<div>{gluttonUserLabel}</div>
			</FirstLevelMenu>
			: null
		}

		{isAdminDisplay()
			? <FirstLevelMenu onClick={onAccountCliked} title={collapsed ? accountLabel : undefined}
							  data-active={onAccount}>
				<FontAwesomeIcon icon={faUserEdit}/>
				<div>{accountLabel}</div>
			</FirstLevelMenu>
			: null
		}

		{(isPlatformDisplay() || isBillAdminDisplay())
			? null
			: <FirstLevelMenu onClick={onMaskCliked} title={collapsed ? maskLabel : undefined}
							  data-active={onMask}>
				<FontAwesomeIcon icon={faEyeSlash}/>
				<div>{maskLabel}</div>
			</FirstLevelMenu>
		}

        {isAdminDisplay()
            ? <FirstLevelMenu onClick={onSourceConfigCliked} title={collapsed ? sourceConfigLabel : undefined}
                              data-active={onSourceConfig}>
                <FontAwesomeIcon icon={faCog}/>
                <div>{sourceConfigLabel}</div>
            </FirstLevelMenu>
            : null
        }

		{(isAdminDisplay() || isBillAdminDisplay())
		    ? <FirstLevelMenu onClick={onRecordTraceClicked} title={collapsed ? recordLabelTrace : undefined}
        		    data-active={onRecordTrace}>
        	        <FontAwesomeIcon icon={faHistory}/>
        	        <div>{recordLabelTrace}</div>
			  </FirstLevelMenu>
			: null
		}

		{(isAdminDisplay() || isBillAdminDisplay())
			? <FirstLevelMenu onClick={onRecordBlockchainClicked} title={collapsed ? recordLabelBlockchain : undefined}
        		     data-active={onRecordBlockchain}>
        	         <FontAwesomeIcon icon={faLink}/>
        	         <div>{recordLabelBlockchain}</div>
			  </FirstLevelMenu>
			: null
		}

		{isAdminDisplay() || isMEJ()
			? <FirstLevelMenu onClick={onOperationRecordCliked} title={collapsed ? operationRecordLabel : undefined}
							  data-active={onOperationRecord}>
				<FontAwesomeIcon icon={faHistory}/>
				<div>{operationRecordLabel}</div>
			</FirstLevelMenu>
			: null
		}

        {isAdminDisplay()
            ? <FirstLevelMenu onClick={onQualityInspectionClick} title={collapsed ? qualityInspectionLabel : undefined}
                              data-active={onQualityInspection}>
                <FontAwesomeIcon icon={faStethoscope}/>
                <div>{qualityInspectionLabel}</div>
            </FirstLevelMenu>
            : null
        }

		{isAdminDisplay()
			? <FirstLevelMenu onClick={onQualityInspectionTaskClick} title={collapsed ? qualityInspectionTaskLabel : undefined}
							  data-active={onQualityInspectionTask}>
				<FontAwesomeIcon icon={faStethoscope}/>
				<div>{qualityInspectionTaskLabel}</div>
			</FirstLevelMenu>
			: null
		}

		{isAdminDisplay()
			? <FirstLevelMenu onClick={onTraceReportMonthClick} title={collapsed ? traceReportMonthLabel : undefined}
							  data-active={onTraceReportMonth}>
				<FontAwesomeIcon icon={faFileExport}/>
				<div>{traceReportMonthLabel}</div>
			</FirstLevelMenu>
			: null
		}

		{isAdminDisplay()
			? <FirstLevelMenu onClick={onTraceReportDayClick} title={collapsed ? traceReportDayLabel : undefined}
							  data-active={onTraceReportDay}>
				<FontAwesomeIcon icon={faFileExport}/>
				<div>{traceReportDayLabel}</div>
			</FirstLevelMenu>
			: null
		}

       {/* {isAdminDisplay()
            ? <FirstLevelMenu onClick={onTraceInspectionBusinessClick} title={collapsed ? traceInspectionBusinessLabel : undefined}
                              data-active={onTraceInspectionBusiness}>
                <FontAwesomeIcon icon={faFileExport}/>
                <div>{traceInspectionBusinessLabel}</div>
            </FirstLevelMenu>
            : null
        }*/}
        {isAdminDisplay()
            ? <FirstLevelMenu onClick={onSystemOperationRecordClick} title={collapsed ? systemOperationRecordLabel : undefined}
                              data-active={onSystemOperationRecord}>
                <FontAwesomeIcon icon={faHistory}/>
                <div>{systemOperationRecordLabel}</div>
            </FirstLevelMenu>
            : null
        }

		{/*{isDemoHide() || isCCICDisplay()
			? null
			: <FirstLevelMenu onClick={onDemoClicked} title={collapsed ? demoLabel : undefined}
			                  data-active={onDemo}>
				<FontAwesomeIcon icon={faVideo}/>
				<div>{demoLabel}</div>
			</FirstLevelMenu>}*/}
		<FirstLevelMenu onClick={onToggleMenuClicked} title={collapsed ? expandLabel : undefined}>
			<FontAwesomeIcon icon={faAngleDoubleLeft}/>
			<div>隐藏菜单</div>
		</FirstLevelMenu>
	</SideBar>;
}