import React, {useEffect, useReducer, useState} from 'react';
import styled from 'styled-components';
import {
    Button,
    ButtonBar,
    ButtonBarAlignType,
    ButtonType,
    DateTimeRange, Dropdown,
    FormSectionGapVertical,
    PanelSeparator,
    QueryPanel,
    QueryResultColumns,
    QueryResultPanel,
    QueryResultRow,
    Text,
    WorkAreaTitle
} from '../component';
import {
    findSystemOperationRecordDataPage, OperationLogPage,
    TraceCriteria,
    TraceDataPage,
    exportOperationRecordData, SystemOperationLogPage, SystemOperationLogCriteria, SystemOperationLogData
} from '../service/trace-service';
import {endOfDate, formatFull, startOfDate} from "../service/date";
import {AccountData, getTenantList} from "../service/account-service";
import {AccountList} from "../service/record-service";
import {DropdownOption} from "../component/dropdown";

const TraceQuery = styled.div`
	display: flex;
	flex-direction: column;
`;

const ButtonWrapper = styled.div`
	width: max-content;
	text-align: center;
	> *:last-child {
		display: inline-block;
		margin-left: var(--gap-thin);
	}
`;

const columns: QueryResultColumns = [
    { label: '#', width: '40px', name: 'seq' },
    {
        label: <React.Fragment>
            <div>操作类型</div>
        </React.Fragment>, width: '140px', name: [ 'operType' ]
    },
    {
        label: <React.Fragment>
            <div>操作对象</div>
        </React.Fragment>, width: '140px', name: [ 'operObj' ]
    },
    {
        label: <React.Fragment>
            <div>操作说明</div>
        </React.Fragment>, width: '360px', name: [ 'operDes' ]
    },{
        label: <React.Fragment>
            <div>操作人</div>
        </React.Fragment>, width: '140px', name: [ 'operAccount' ]
    },
    {
        label: <React.Fragment>
            <div>操作时间</div>
        </React.Fragment>, width: '140px', name: [ 'createAt' ]
    }
];

type InputCriteria = Exclude<keyof SystemOperationLogCriteria, 'pageSize' | 'pageNumber'>

export default () => {
    const now = new Date();
    const OperationTypes = new Array<DropdownOption>({value:'',label:'请选择'},{value:'1',label:'登录'},{value:'2',label:'新增CAS账户'},{value:'3',label:'删除CAS账户'},{value:'4',label:'CAS权限处理'},{value:'5',label:'脱敏配置'},{value:'6',label:'版本管理配置'},{value:'7',label:'新增系统'});
    const [ , forceUpdate ] = useReducer(x => x + 1, 0);
    const [ data, setData ] = useState<SystemOperationLogPage | null>(null);
    const [ searched, setSearched ] = useState(false);
    const [tenantList,setTenantList] = useState<Array<DropdownOption> | []>([]);
    const [ criteria ] = useState<SystemOperationLogCriteria>({
        createFrom: formatFull(startOfDate(now)),
        createTo: formatFull(endOfDate(now)),
    });

    useEffect(() => {
        (async () => {
            const tenantlist : AccountList =await getTenantList();
            const accounts = tenantlist?.accounts || [];
            const tenant = new Array<DropdownOption>({value:'',label:'请选择'});
            accounts.forEach((account)=>{
                tenant.push({value:account.tenantId,label:account.accountName});
            });
            setTenantList(tenant);
        })();
    }, []);

    const [ currentCriteria, setCurrentCriteria ] = useState<SystemOperationLogCriteria | null>(null);

    const onChanged = (propName: InputCriteria) => (event: React.ChangeEvent) => {
        // @ts-ignore
        criteria[propName] = (event.target as HTMLInputElement).value;
        forceUpdate();
    };

    const doSearch = async (pagination: SystemOperationLogCriteria) => {
        // 设置当前查询
        setCurrentCriteria(pagination);
        const data = await findSystemOperationRecordDataPage(pagination);
        setData(data);
        setSearched(true);
    };
    const onSearchClicked = async (criteria: TraceCriteria) => {
        const pagination = {
            ...criteria,
            pageNumber: 1,
            pageSize: 10
        };
        await doSearch(pagination);
    };

    const onExportClicked = async(criteria: TraceCriteria) =>{
        const pagination = {
            ...criteria,
            pageNumber: 1,
            pageSize: 10
        };
        await doExport(pagination);
    }

    const onPageChanged = async (pageNumber: number) => {
        const pagination = {
            ...currentCriteria,
            pageNumber
        };
        await doSearch(pagination);
    };

    const doExport =  (pagenation:TraceCriteria)=>{
        setCurrentCriteria(pagenation);
        exportOperationRecordData(pagenation);
    };



    const dataFound = data || { pageNumber: 1, pageSize: 10, totalPages: 1, totalItemCount: 0, rows: [] };

    const rows = (dataFound.rows || []).map((row): QueryResultRow => {
        return row as QueryResultRow;
    });

    const onDateChanged = (startDate?: Date, endDate?: Date) => {
        criteria.createFrom = formatFull(startOfDate(startDate));
        criteria.createTo = formatFull(endOfDate(endDate));
        forceUpdate();
    };
    const traceUpdate = () => {
        forceUpdate();
    };
    // noinspection DuplicatedCode
    return <TraceQuery>
        <WorkAreaTitle title="回溯审计操作记录"/>
        <QueryPanel>
            <DateTimeRange label="操作时间" columns={2} startDate={criteria.createFrom!} endDate={criteria.createTo!}
                           onChange={onDateChanged} update={traceUpdate} />
            <Text label="操作人" placeholder="请输入" value={criteria.operAccount} onChange={onChanged('operAccount')}/>

            <Dropdown label="操作类型" placeholder="请输入" options={OperationTypes} value={criteria.operType} onChange={onChanged('operType')}/>
            <Text label="操作对象" placeholder="请输入" value={criteria.operObj} onChange={onChanged('operObj')}/>
            <PanelSeparator columns={3}/>
            <ButtonBar align={ButtonBarAlignType.CENTER}>
                <Button label="查询" onClick={() => onSearchClicked(criteria)}/>
                {/*<Button label="导出" onClick={() => onExportClicked(criteria)} />*/}
            </ButtonBar>
            <PanelSeparator columns={3}/>
        </QueryPanel>
        <FormSectionGapVertical/>
        <QueryResultPanel columns={columns} rows={rows} pagination={true} searched={searched}
                          pageCount={dataFound.totalPages} pageNumber={dataFound.pageNumber}
                          totalItemCount={dataFound.totalItemCount}
                          pageChange={onPageChanged}/>
    </TraceQuery>;
}