import Account from './account-model';

export const getAccount = () => {
	const accountString = sessionStorage.getItem("glutton-account");
	if (accountString) {
		try {
			const account: Account = JSON.parse(accountString);
			if (account.accountName && account.token) {
				account.isAuthorized = true;
			}
			return account;
		} catch {
			return {
				isAuthorized: false
			};
		}
	}
	return {
		isAuthorized: false
	};
};

export const getAccountRoles = () => {
	const accounRolestString = sessionStorage.getItem("glutton-account-role");
	if (accounRolestString) {
		try {
			const accountRole: Array<string> = JSON.parse(accounRolestString);
			return accountRole;
		} catch {
			return [];
		}
	}
	return [];
};


export const clearAccount = () => {
	sessionStorage.removeItem("glutton-account");
	sessionStorage.removeItem("glutton-account-role");
};

export const Role_Admin = 'ROLE_ADMIN';
export const Role_PLATFORM_ADMIN = 'ROLE_PLATFORM_ADMIN';
export const Role_BILL_ADMIN = 'ROLE_BILL_ADMIN';
export const Role_Tenant_Admin = 'ROLE_TENANT_ADMIN';

export const isAdminLogin = () => {
	return getAccountRoles().includes(Role_Admin);
};
export const isPlatformLogin = () => {
	return getAccountRoles().includes(Role_PLATFORM_ADMIN);
};
export const isBillAdminLogin = () => {
	return getAccountRoles().includes(Role_BILL_ADMIN);
};
export const isTenantAdminLogin = () => {
	return getAccountRoles().includes(Role_Tenant_Admin);
};