import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Path from '../path';
import TraceInspection from './trace-inspection-query';


export default () => {
	return <Switch>
		<Route path={Path.TRACE_INSPECTION} exact render={({ match }) => {
            return  <TraceInspection/>
        }}/>
	</Switch>;
}