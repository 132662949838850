import captureSnapshot from './capture-snapshot';
import { os } from './device';
import MutationBuffer, { MutationListener } from './mutation';
import {
	BlockClass,
	CanvasData,
	Hooks,
	HTMLEventListenerReleaser,
	IncrementalEventType,
	INode,
	InputData,
	InputValue,
	MaskInputOptions,
	MediaInteraction,
	MediaInteractionData,
	MouseInteraction,
	MouseInteractionData,
	MouseMoveData,
	MousePosition,
	MutationData,
	RecorderOptions,
	SamplingStrategy,
	ScrollData,
	StopRecord,
	StyleSheetRuleData,
	ViewportResizeData,
	WebEvent,
	WebEventListener,
	WebEventSerializer,
	WebEventType,
	WebEventWithTime
} from './types';
import {
	getWindowHeight,
	getWindowWidth,
	isBlocked,
	isTouchEvent,
	mirror,
	on,
	polyfillNodeForEach,
	throttle
} from './utils';

const INPUT_TAGS = [ 'INPUT', 'TEXTAREA', 'SELECT' ];

type MouseOrTouchMoveListener = (source: IncrementalEventType.MouseMove | IncrementalEventType.TouchMove, data: MouseMoveData) => void;
type MouseInteractionListener = (data: MouseInteractionData) => void;
type ScrollListener = (data: ScrollData) => void;
type ViewportResizeListener = (data: ViewportResizeData) => void;
type InputListener = (data: InputData) => void;
type MediaInteractionListener = (data: MediaInteractionData) => void;
type StyleSheetRuleListener = (data: StyleSheetRuleData) => void;
type CanvasListener = (data: CanvasData) => void;

type ObserverOptions = {
	onMutation: MutationListener,
	onMouseMove: MouseOrTouchMoveListener,
	onMouseInteraction: MouseInteractionListener,
	onScroll: ScrollListener,
	onViewportResize: ViewportResizeListener,
	onInput: InputListener,
	onMediaInteraction: MediaInteractionListener,
	onStyleSheetRule: StyleSheetRuleListener,
	onCanvas: CanvasListener,

	blockClass: BlockClass,
	ignoreClass: string,
	maskInputOptions: MaskInputOptions,
	inlineStylesheet: boolean,
	sampling: SamplingStrategy,
}
type HookResetter = () => void;

const wrapEvent = (e: WebEvent): WebEventWithTime => {
	return { ...e, timestamp: Date.now() };
};
const buildMaskInputOptions = (maskAllInputs: boolean = false, fallback?: MaskInputOptions): MaskInputOptions => {
	return maskAllInputs
		? {
			color: true,
			date: true,
			'datetime-local': true,
			email: true,
			month: true,
			number: true,
			range: true,
			search: true,
			tel: true,
			text: true,
			time: true,
			url: true,
			week: true,
			textarea: true,
			select: true
		} : (fallback || {});
};
const hookSetter = <T>(
	target: T,
	key: string | number | symbol,
	propertyDescriptor: PropertyDescriptor,
	isRevoked?: boolean,
	win = window
): HookResetter => {
	const original = win.Object.getOwnPropertyDescriptor(target, key);
	win.Object.defineProperty(
		target,
		key,
		isRevoked
			? propertyDescriptor
			: {
				set(value) {
					// put hooked setter into event loop to avoid of set latency
					setTimeout(() => {
						propertyDescriptor.set!.call(this, value);
					}, 0);
					if (original && original.set) {
						original.set.call(this, value);
					}
				}
			}
	);
	return () => hookSetter(target, key, original || {}, true);
};

class Recorder {
	private readonly onEvent: WebEventListener;
	private readonly checkoutEveryNth?: number;
	private readonly checkoutEveryNms?: number;
	private readonly blockClass: string | RegExp;
	private readonly ignoreClass: string;
	private readonly maskInputOptions: MaskInputOptions;
	private readonly inlineStylesheet: boolean;
	private readonly hooks?: Hooks;
	private readonly serializeEvent?: WebEventSerializer;
	private readonly sampling: SamplingStrategy;
	private readonly canvasImperative: Array<string>;
	private readonly iconFonts: Array<string>;

	private lastFullSnapshotEvent: WebEventWithTime | null = null;
	private incrementalSnapshotCount: number = 0;
	private lastInputValueMap: WeakMap<EventTarget, InputValue> = new WeakMap();

	// value is timeout number; key is canvas2d, use object type
	private lastCanvasSnapshotTimeout = new Map<object, number>();

	constructor(options: RecorderOptions) {
		const {
			onEvent,
			checkoutEveryNth,
			checkoutEveryNms,
			blockClass = 'rr-block',
			ignoreClass = 'rr-ignore',
			maskAllInputs,
			maskInputOptions,
			inlineStylesheet = true,
			hooks,
			serializeEvent,
			sampling = {},
			canvasImperative = [ 'drawImage', 'putImageData' ],
			iconFonts = []
		} = options;

		// runtime checks for user options
		if (!onEvent) {
			throw new Error('Event listener not defined.');
		}

		polyfillNodeForEach();

		this.onEvent = onEvent;
		this.checkoutEveryNth = checkoutEveryNth;
		this.checkoutEveryNms = checkoutEveryNms;
		this.blockClass = blockClass;
		this.ignoreClass = ignoreClass;
		this.inlineStylesheet = inlineStylesheet;
		this.maskInputOptions = buildMaskInputOptions(maskAllInputs, maskInputOptions);
		this.hooks = hooks;
		this.serializeEvent = serializeEvent;
		this.sampling = sampling;
		this.canvasImperative = canvasImperative;
		this.iconFonts = iconFonts;
	}

	public startRecord(): StopRecord {
		try {
			const handlers: Array<HTMLEventListenerReleaser> = [];
			handlers.push(on('DOMContentLoaded', () => {
				this.fireEvent(wrapEvent({
					type: WebEventType.DomContentLoaded,
					data: {}
				}));
			}));
			this.doStartRecordOnDocReadyState(window, document, handlers);
			return () => {
				handlers.forEach((h) => h());
			};
		} catch (error) {
			// TODO: handle internal error
			console.warn(error);
			return () => {
			};
		}
	}

	private doStartRecordOnDocReadyState(
		targetWindow: Window = window,
		targetDocument: Document = document,
		handlers: Array<HTMLEventListenerReleaser>
	) {
		if (targetDocument.readyState === 'interactive' || targetDocument.readyState === 'complete') {
			this.doStartRecord(handlers);
		} else {
			handlers.push(on('load', () => {
				this.fireEvent(wrapEvent({
					type: WebEventType.Loaded,
					data: {}
				}));
				this.doStartRecord(handlers);
			}, targetWindow));
		}
	}

	private doStartRecord(handlers: Array<HTMLEventListenerReleaser>): void {
		this.takeFullSnapshot();

		handlers.push(this.initObservers({
			onMutation: (data: MutationData) => {
				// console.log('mutation', data);
				this.fireEvent(wrapEvent({
					type: WebEventType.IncrementalSnapshot,
					data: { source: IncrementalEventType.Mutation, ...data }
				}));
			},
			onMouseMove: (source: IncrementalEventType.MouseMove | IncrementalEventType.TouchMove, data: MouseMoveData) =>
				this.fireEvent(wrapEvent({
					type: WebEventType.IncrementalSnapshot,
					data: { source, ...data }
				})),
			onMouseInteraction: (data: MouseInteractionData) =>
				this.fireEvent(wrapEvent({
					type: WebEventType.IncrementalSnapshot,
					data: { source: IncrementalEventType.MouseInteraction, ...data }
				})),
			onScroll: (data: ScrollData) =>
				this.fireEvent(wrapEvent({
					type: WebEventType.IncrementalSnapshot,
					data: { source: IncrementalEventType.Scroll, ...data }
				})),
			onViewportResize: (data: ViewportResizeData) =>
				this.fireEvent(wrapEvent({
					type: WebEventType.IncrementalSnapshot,
					data: { source: IncrementalEventType.ViewportResize, ...data }
				})),
			onInput: (data: InputData) =>
				this.fireEvent(wrapEvent({
					type: WebEventType.IncrementalSnapshot,
					data: { source: IncrementalEventType.Input, ...data }
				})),
			onMediaInteraction: (data: MediaInteractionData) =>
				this.fireEvent(wrapEvent({
					type: WebEventType.IncrementalSnapshot,
					data: { source: IncrementalEventType.MediaInteraction, ...data }
				})),
			onStyleSheetRule: (data: StyleSheetRuleData) =>
				this.fireEvent(wrapEvent({
					type: WebEventType.IncrementalSnapshot,
					data: { source: IncrementalEventType.StyleSheetRule, ...data }
				})),
			onCanvas: (data: CanvasData) => {
				// console.log('canvas', data);
				if(data?.rr_height !== '0px' && data?.rr_width !== '0px'){
					this.fireEvent(wrapEvent({
						type: WebEventType.IncrementalSnapshot,
						data: { source: IncrementalEventType.Canvas, ...data }
					}));
				}
			},
			blockClass: this.blockClass,
			ignoreClass: this.ignoreClass,
			maskInputOptions: this.maskInputOptions,
			inlineStylesheet: this.inlineStylesheet,
			sampling: this.sampling
		}));
	}

	private mergeHooks(options: ObserverOptions, hooks: Hooks = {}) {
		const {
			onMutation,
			onMouseMove,
			onMouseInteraction,
			onScroll,
			onViewportResize,
			onInput,
			onMediaInteraction,
			onStyleSheetRule
		} = options;
		options.onMutation = (data: MutationData) => {
			if (hooks.mutation) {
				hooks.mutation(data, IncrementalEventType.Mutation);
			}
			onMutation(data);
		};
		options.onMouseMove = (source: IncrementalEventType.MouseMove | IncrementalEventType.TouchMove, data: MouseMoveData) => {
			if (hooks.mousemove) {
				hooks.mousemove(data, source);
			}
			onMouseMove(source, data);
		};
		options.onMouseInteraction = (data: MouseInteractionData) => {
			if (hooks.mouseInteraction) {
				hooks.mouseInteraction(data, IncrementalEventType.MouseInteraction);
			}
			onMouseInteraction(data);
		};
		options.onScroll = (data: ScrollData) => {
			if (hooks.scroll) {
				hooks.scroll(data, IncrementalEventType.Scroll);
			}
			onScroll(data);
		};
		options.onViewportResize = (data: ViewportResizeData) => {
			if (hooks.viewportResize) {
				hooks.viewportResize(data, IncrementalEventType.ViewportResize);
			}
			onViewportResize(data);
		};
		options.onInput = (data: InputData) => {
			if (hooks.input) {
				hooks.input(data, IncrementalEventType.Input);
			}
			onInput(data);
		};
		options.onMediaInteraction = (data: MediaInteractionData) => {
			if (hooks.mediaInteraction) {
				hooks.mediaInteraction(data, IncrementalEventType.MediaInteraction);
			}
			onMediaInteraction(data);
		};
		options.onStyleSheetRule = (data: StyleSheetRuleData) => {
			if (hooks.styleSheetRule) {
				hooks.styleSheetRule(data, IncrementalEventType.StyleSheetRule);
			}
			onStyleSheetRule(data);
		};
	}

	private initObservers(options: ObserverOptions): HTMLEventListenerReleaser {
		this.mergeHooks(options, this.hooks);

		const incrementalReleasers: Array<HTMLEventListenerReleaser | null> = [];
		// 监听指定document包含的iframe, 层级递进
		const monitorFrames = (targetWindow: Window, targetDocument: Document): Array<HTMLEventListenerReleaser | null> => {
			// 初始化iframe监听器, 只初始化一层
			return Array.from(targetDocument.querySelectorAll('iframe'))
				.map(iframe => {
					if (iframe.contentDocument) {
						if (iframe.contentDocument.readyState === 'interactive' || iframe.contentDocument.readyState === 'complete') {
							// 加载完毕
							const { width: firstWidth, height: firstHeight } = iframe.getBoundingClientRect();
							if (firstWidth === 0 && firstHeight === 0) {
								// means not show
								const visibleListener = () => {
									const { width, height } = iframe.getBoundingClientRect();
									if (width === 0 && height === 0) {
										// becomes hidden, do nothing
									} else {
										console.error('Take full snapshot since visibility changed.', iframe, iframe.contentDocument!.readyState);
										// becomes visible, capture full snapshot
										const releasers = watch(iframe.contentWindow!, iframe.contentDocument!);
										incrementalReleasers.push(...[ ...releasers, ...monitorFrames(iframe.contentWindow!, iframe.contentDocument!) ]);
									}
								};
								iframe.contentDocument.addEventListener('visibilitychange', visibleListener);
								return [];
							} else {
								const releasers = watch(iframe.contentWindow!, iframe.contentDocument);
								return [ ...releasers, ...monitorFrames(iframe.contentWindow!, iframe.contentDocument) ];
							}
						} else {
							iframe.contentDocument.addEventListener('DOMContentLoaded', () => {
								// 加载完毕
								const releasers = watch(iframe.contentWindow!, iframe.contentDocument!);
								return [ ...releasers, ...monitorFrames(iframe.contentWindow!, iframe.contentDocument!) ];
							});
							return [];
						}
					} else {
						// 还没有加载完毕
						const onIframeLoad = (event: Event) => {
							const target = event.target as HTMLIFrameElement;
							target.removeEventListener('load', onIframeLoad);
							// 因为src变化重新载入之后, 需要全部重新添加监听
							const releasers = watch(target.contentWindow!, target.contentDocument!);
							incrementalReleasers.push(...[ ...releasers, ...monitorFrames(target.contentWindow!, target.contentDocument!) ]);
						};
						iframe.addEventListener('load', onIframeLoad);
						return [];
					}
				})
				.reduce((releasers, every) => {
					return [ ...releasers, ...every ];
				}, []);
		};
		const watch = (targetWindow: Window = window, targetDocument: Document = document) => {
			return [
				this.initMutationObserver(
					options.onMutation, options.blockClass, options.inlineStylesheet, options.maskInputOptions, targetWindow, targetDocument,
					(targetWindow: Window, targetDocument: Document) => {
						// console.error('take full snapshot since iframe change detected.');
						// 有iframe
						this.takeFullSnapshot(false);
						const releasers = watch(targetWindow, targetDocument);
						incrementalReleasers.push(...releasers);
					}
				),
				// 只抓顶层
				targetWindow === window ? this.initMouseOrTouchMoveObserver(options.onMouseMove, options.sampling) : null,
				this.initMouseInteractionObserver(options.onMouseInteraction, options.blockClass, options.sampling, targetDocument),
				this.initScrollObserver(options.onScroll, options.blockClass, options.sampling, targetDocument),
				// 只抓顶层
				targetWindow === window ? this.initViewportResizeObserver(options.onViewportResize) : null,
				this.initInputObserver(options.onInput, options.blockClass, options.ignoreClass, options.maskInputOptions, options.sampling, targetWindow, targetDocument),
				this.initMediaInteractionObserver(options.onMediaInteraction, options.blockClass, targetDocument),
				this.initStyleSheetObserver(options.onStyleSheetRule, targetWindow),
				this.initCanvasObserver(options.onCanvas, options.blockClass, options.ignoreClass, options.sampling, targetWindow),
				// 初始化iframe监听器
				...monitorFrames(targetWindow, targetDocument)
			].filter(x => x != null);
		};

		// 初始化顶层监听器
		// console.log('Start to watch top window');
		const releasers = watch();
		return () => {
			[ ...releasers, ...incrementalReleasers ].forEach(release => {
				try {
					release && release!();
				} catch {
					console.warn('Failed to release monitor(s) of page, may lead memory link, be attention at this, keep observing.');
				}
			});
		};
	}

	private initMutationObserver(
		onMutation: MutationListener,
		blockClass: BlockClass,
		inlineStylesheet: boolean,
		maskInputOptions: MaskInputOptions,
		targetWindow: Window = window,
		targetDocument: Document = document,
		watch: (targetWindow: Window, targetDocument: Document) => void
	): HTMLEventListenerReleaser {
		if (targetDocument == null) {
			return () => {};
		}
		// see mutation.ts for details
		const mutationBuffer = new MutationBuffer(
			targetWindow,
			targetDocument,
			onMutation,
			blockClass,
			inlineStylesheet,
			maskInputOptions,
			watch
		);
		const observer = new MutationObserver(mutationBuffer.processMutations);
		try{
			observer.observe(targetDocument, {
				attributes: true,
				attributeOldValue: true,
				characterData: true,
				characterDataOldValue: true,
				childList: true,
				subtree: true
			});
		}catch{
			console.log('Failed to execute observer on MutationObserver');
		}

		return () => observer.disconnect();
	}

	private initMouseOrTouchMoveObserver(
		onMouseOrTouchMove: MouseOrTouchMoveListener,
		sampling: SamplingStrategy,
		targetDocument: Document = document
	): HTMLEventListenerReleaser {
		if (sampling.mousemove === false) {
			return () => {
			};
		}

		const threshold = typeof sampling.mousemove === 'number' ? sampling.mousemove : 50;

		let positions: Array<MousePosition> = [];
		let timeBaseline: number | null;
		const wrappedCb = throttle((isTouch: boolean) => {
			const totalOffset = Date.now() - timeBaseline!;
			onMouseOrTouchMove(
				isTouch ? IncrementalEventType.TouchMove : IncrementalEventType.MouseMove,
				{
					positions: positions.map((p) => {
						p.timeOffset -= totalOffset;
						return p;
					})
				}
			);
			positions = [];
			timeBaseline = null;
		}, 500);
		const updatePosition = throttle<MouseEvent | TouchEvent>(
			(evt) => {
				const { target } = evt;
				const { clientX, clientY } = isTouchEvent(evt)
					? evt.changedTouches[0]
					: evt;
				if (!timeBaseline) {
					timeBaseline = Date.now();
				}
				positions.push({
					x: clientX,
					y: clientY,
					id: mirror.getId(target as INode),
					timeOffset: Date.now() - timeBaseline
				});
				wrappedCb(isTouchEvent(evt));
			},
			threshold,
			{
				trailing: false
			}
		);
		const handlers = [
			// @ts-ignore
			on('mousemove', updatePosition, targetDocument),
			// @ts-ignore
			on('touchmove', updatePosition, targetDocument)
		];
		return () => {
			handlers.forEach((h) => h());
		};
	}

	private initMouseInteractionObserver(
		onMouseInteraction: MouseInteractionListener,
		blockClass: BlockClass,
		sampling: SamplingStrategy,
		targetDocument: Document = document
	): HTMLEventListenerReleaser {
		if (sampling.mouseInteraction === false) {
			return () => {
			};
		}
		const disableMap: Record<string, boolean | undefined> =
			sampling.mouseInteraction === true ||
			sampling.mouseInteraction === undefined
				? {}
				: sampling.mouseInteraction;

		const handlers: Array<HTMLEventListenerReleaser> = [];
		const getHandler = (eventKey: keyof typeof MouseInteraction) => {
			return (event: MouseEvent | TouchEvent) => {
				if (isBlocked(event.target as Node, blockClass)) {
					return;
				}
				const id = mirror.getId(event.target as INode);
				const { clientX, clientY } = isTouchEvent(event)
					? event.changedTouches[0]
					: event;
				onMouseInteraction({
					type: MouseInteraction[eventKey],
					id,
					x: clientX,
					y: clientY
				});
			};
		};
		Object.keys(MouseInteraction)
			.filter(
				(key) =>
					Number.isNaN(Number(key)) &&
					!key.endsWith('_Departed') &&
					disableMap[key] !== false
			)
			// @ts-ignore
			.forEach((eventKey: keyof typeof MouseInteraction) => {
				const eventName = eventKey.toLowerCase();
				const handler = getHandler(eventKey);
				// @ts-ignore
				handlers.push(on(eventName, handler, targetDocument));
			});
		return () => {
			handlers.forEach((h) => h());
		};
	}

	private initScrollObserver(
		onScroll: ScrollListener,
		blockClass: BlockClass,
		sampling: SamplingStrategy,
		targetDocument: Document = document
	): HTMLEventListenerReleaser {
		const updatePosition = throttle<UIEvent>((evt: UIEvent) => {
			if (!evt.target || isBlocked(evt.target as Node, blockClass)) {
				return;
			}
			const id = mirror.getId(evt.target as INode);
			if (evt.target === targetDocument) {
				const scrollEl = (targetDocument.scrollingElement || targetDocument.documentElement)!;
				onScroll({
					id,
					x: scrollEl.scrollLeft,
					y: scrollEl.scrollTop
				});
			} else {
				onScroll({
					id,
					x: (evt.target as HTMLElement).scrollLeft,
					y: (evt.target as HTMLElement).scrollTop
				});
			}
		}, sampling.scroll || 100);
		// @ts-ignore
		return on('scroll', updatePosition, targetDocument);
	}

	private initViewportResizeObserver(
		onViewportResize: ViewportResizeListener,
		targetWindow: Window = window,
		targetDocument: Document = document
	): HTMLEventListenerReleaser {
		if (targetWindow === window && (os.phone || os.tablet)) {
			// 如果是手机或者平板, 不需要监控resize事件
			return () => {
			};
		}
		const updateDimension = throttle(() => {
			const height = getWindowHeight(targetWindow, targetDocument);
			const width = getWindowWidth(targetWindow, targetDocument);
			onViewportResize({
				width: Number(width),
				height: Number(height)
			});
		}, 200);
		return on('resize', updateDimension, targetWindow);
	}

	private initInputObserver(
		onInput: InputListener,
		blockClass: BlockClass,
		ignoreClass: string,
		maskInputOptions: MaskInputOptions,
		sampling: SamplingStrategy,
		targetWindow: Window = window,
		targetDocument: Document = document
	): HTMLEventListenerReleaser {
		const eventHandler = (event: Event) => {
			const { target } = event;
			if (
				!target ||
				!(target as Element).tagName ||
				INPUT_TAGS.indexOf((target as Element).tagName) < 0 ||
				isBlocked(target as Node, blockClass)
			) {
				return;
			}
			const type: string | undefined = (target as HTMLInputElement).type;
			if (
				type === 'password' ||
				(target as HTMLElement).classList.contains(ignoreClass)
			) {
				return;
			}
			let text = (target as HTMLInputElement).value;
			let isChecked = false;
			if (type === 'radio' || type === 'checkbox') {
				isChecked = (target as HTMLInputElement).checked;
			} else if (
				maskInputOptions[
					(target as Element).tagName.toLowerCase() as keyof MaskInputOptions
					] ||
				maskInputOptions[type as keyof MaskInputOptions]
			) {
				text = '*'.repeat(text.length);
			}
			cbWithDedup(target, { text, isChecked });
			// if a radio was checked
			// the other radios with the same name attribute will be unchecked.
			const name: string | undefined = (target as HTMLInputElement).name;
			if (type === 'radio' && name && isChecked) {
				targetDocument
					.querySelectorAll(`input[type="radio"][name="${name}"]`)
					.forEach((el) => {
						if (el !== target) {
							cbWithDedup(el, {
								text: (el as HTMLInputElement).value,
								isChecked: !isChecked
							});
						}
					});
			}
		};

		const cbWithDedup = (target: EventTarget, v: InputValue) => {
			const lastInputValue = this.lastInputValueMap.get(target);
			if (
				!lastInputValue ||
				lastInputValue.text !== v.text ||
				lastInputValue.isChecked !== v.isChecked
			) {
				this.lastInputValueMap.set(target, v);
				const id = mirror.getId(target as INode);
				onInput({
					...v,
					id
				});
			}
		};

		const events = sampling.input === 'last' ? [ 'change' ] : [ 'input', 'change' ];
		const handlers: Array<HTMLEventListenerReleaser | HookResetter> = events.map((eventName) => on(eventName, eventHandler));
		const propertyDescriptor = Object.getOwnPropertyDescriptor(
			(targetWindow as any).HTMLInputElement.prototype,
			'value'
		);
		const hookProperties: Array<[ HTMLElement, string ]> = [
			[ (targetWindow as any).HTMLInputElement.prototype, 'value' ],
			[ (targetWindow as any).HTMLInputElement.prototype, 'checked' ],
			[ (targetWindow as any).HTMLSelectElement.prototype, 'value' ],
			[ (targetWindow as any).HTMLTextAreaElement.prototype, 'value' ]
		];
		if (propertyDescriptor && propertyDescriptor.set) {
			handlers.push(
				...hookProperties.map((p) =>
					hookSetter<HTMLElement>(p[0], p[1], {
						set() {
							// mock to a normal event
							eventHandler({ target: this } as Event);
						}
					})
				)
			);
		}
		return () => {
			handlers.forEach((h) => h());
		};
	}

	private initCanvasObserver(
		onCanvas: CanvasListener,
		blockClass: BlockClass,
		ignoreClass: string,
		sampling: SamplingStrategy,
		targetWindow: Window = window
	): HTMLEventListenerReleaser {
		// console.log(`Init canvas observer, is in frame[${targetWindow.frameElement == null}]`);
		const eventHandler = (event: Event) => {
			const { target } = event;
			if (!target) {
				return;
			}
			const canvas2d = target as unknown as CanvasRenderingContext2D;
			// console.warn(`Start canvas capture on:`, isBlocked(canvas2d.canvas as Node, blockClass));
			if (isBlocked(canvas2d.canvas as Node, blockClass)) {
				return;
			}
			processWithDeduplicate(canvas2d);
		};

		const processWithDeduplicate = (target: CanvasRenderingContext2D) => {
			// console.warn(`Start process with deduplicate.`);
			let timeout = this.lastCanvasSnapshotTimeout.get(target);
			if (timeout) {
				clearTimeout(timeout);
			}
			timeout = setTimeout(() => {
				this.lastCanvasSnapshotTimeout.delete(target);
				const id = mirror.getId(target.canvas as unknown as INode);
				const image = target.canvas.toDataURL();
				// canvas在重建的时候会被替换为img, 因此可能会丢失样式, 特别是高宽
				const { width, height } = (target.canvas as HTMLElement).getBoundingClientRect();
				// console.warn(`Start canvas capture on canvas ${id}.`, image);
				onCanvas({ id, image, rr_width: `${width}px`, rr_height: `${height}px` });
			}, 0);
			this.lastCanvasSnapshotTimeout.set(target, timeout);
		};

		const handlers: Array<HTMLEventListenerReleaser | HookResetter> = [];

		const hookProperties: Array<string> = [
			'fillStyle',
			'font',
			'globalAlpha',
			'globalCompositeOperation',
			'imageSmoothingEnabled',
			'imageSmoothingQuality',
			'lineCap',
			'lineDashOffset',
			'lineJoin',
			'lineWidth',
			'miterLimit',
			'shadowBlur',
			'shadowColor',
			'shadowOffsetX',
			'shadowOffsetY',
			'strokeStyle',
			'textAlign',
			'textBaseline'
		];
		handlers.push(
			...hookProperties.map((propertyName) => {
				// console.log(`Hack canvas property[${propertyName}], is in frame[${targetWindow.frameElement == null}]`);
				return hookSetter<CanvasRenderingContext2D>((targetWindow as any).CanvasRenderingContext2D.prototype, propertyName, {
					set() {
						// mock to a normal event
						// console.log(`Call canvas property[${propertyName}] setter, is in frame[${targetWindow.frameElement == null}]`);
						eventHandler({ target: this } as Event);
					}
				});
			})
		);
		const hookMethods: Array<string> = [
			'addHitRegion',
			'arc',
			'arcTo',
			'beginPath',
			'bezierCurveTo',
			'clearHitRegions',
			'clearRect',
			'clip',
			'closePath',
			'drawFocusIfNeeded',
			'drawImage',
			'drawWidgetAsOnScreen',
			'drawWindow',
			'ellipse',
			'fill',
			'fillRect',
			'fillText',
			'lineTo',
			'moveTo',
			'putImageData',
			'quadraticCurveTo',
			'rect',
			'removeHitRegion',
			'resetTransform',
			'restore',
			'rotate',
			'save',
			'scale',
			'setLineDash',
			'setTransform',
			'stroke',
			'strokeRect',
			'strokeText',
			'transform',
			'translate'
		].filter(methodName => !this.canvasImperative.includes(methodName));
		handlers.push(
			...hookMethods.map((methodName) => {
				// console.log(`Hack canvas method[${methodName}], is in frame[${targetWindow.frameElement == null}]`);
				// @ts-ignore
				const originalMethod = targetWindow.CanvasRenderingContext2D.prototype[methodName];
				// @ts-ignore
				targetWindow.CanvasRenderingContext2D.prototype[methodName] = function () {
					// mock to a normal event
					// console.log(`Call canvas method[${methodName}], is in frame[${targetWindow.frameElement == null}]`);
					originalMethod.apply(this, Array.prototype.slice.call(arguments));
					eventHandler({ target: this } as unknown as Event);
				};
				return () => {
					// @ts-ignore
					targetWindow.CanvasRenderingContext2D.prototype[methodName] = originalMethod;
				};
			})
		);
		const lastCanvasCaptureTimeout = new Map<object, number>();
		this.canvasImperative.map(methodName => {
			// console.log(`Hack canvas imperative method[${methodName}], is in frame[${targetWindow.frameElement == null}]`);
			// @ts-ignore
			const originalMethod = targetWindow.CanvasRenderingContext2D.prototype[methodName];
			// @ts-ignore
			targetWindow.CanvasRenderingContext2D.prototype[methodName] = function () {
				// console.log(`Call canvas imperative method[${methodName}], is in frame[${targetWindow.frameElement == null}]`);
				let timeout = lastCanvasCaptureTimeout.get(this);
				if (timeout) {
					clearTimeout(timeout);
				}
				// mock to a normal event
				// console.error(methodName);
				timeout = setTimeout(() => {
					lastCanvasCaptureTimeout.delete(this);
					const id = mirror.getId(this.canvas as unknown as INode);
					const image = this.canvas.toDataURL();
					// canvas在重建的时候会被替换为img, 因此可能会丢失样式, 特别是高宽
					const { width, height } = (this.canvas as HTMLElement).getBoundingClientRect();
					// console.warn(`Start canvas capture on canvas ${id}.`, image);
					onCanvas({ id, image, rr_width: `${width}px`, rr_height: `${height}px` });
				}, 200);
				lastCanvasCaptureTimeout.set(this, timeout);
				originalMethod.apply(this, Array.prototype.slice.call(arguments));
			};
			return () => {
				// @ts-ignore
				targetWindow.CanvasRenderingContext2D.prototype[methodName] = originalMethod;
			};
		}).forEach(handler => handlers.push(handler));
		return () => {
			handlers.forEach((h) => h());
		};
	}

	private initMediaInteractionObserver(
		onMediaInteraction: MediaInteractionListener,
		blockClass: BlockClass,
		targetDocument: Document = document
	): HTMLEventListenerReleaser {
		const handler = (type: 'play' | 'pause') => (event: Event) => {
			const { target } = event;
			if (!target || isBlocked(target as Node, blockClass)) {
				return;
			}
			onMediaInteraction({
				type: type === 'play' ? MediaInteraction.Play : MediaInteraction.Pause,
				id: mirror.getId(target as INode)
			});
		};
		const handlers = [
			on('play', handler('play'), targetDocument),
			on('pause', handler('pause'), targetDocument)
		];
		return () => {
			handlers.forEach((h) => h());
		};
	}

	private initStyleSheetObserver(
		onStyleSheetRule: StyleSheetRuleListener,
		targetWindow: Window = window
	): HTMLEventListenerReleaser {
		const insertRule = (targetWindow as any).CSSStyleSheet.prototype.insertRule;
		(targetWindow as any).CSSStyleSheet.prototype.insertRule = function (rule: string, index?: number) {
			const id = mirror.getId(this.ownerNode as unknown as INode);
			if (id !== -1) {
				onStyleSheetRule({
					id,
					adds: [ { rule, index } ]
				});
			}
			// @ts-ignore
			return insertRule.apply(this, arguments);
		};

		const deleteRule = (targetWindow as any).CSSStyleSheet.prototype.deleteRule;
		(targetWindow as any).CSSStyleSheet.prototype.deleteRule = function (index: number) {
			const id = mirror.getId(this.ownerNode as unknown as INode);
			if (id !== -1) {
				onStyleSheetRule({
					id,
					removes: [ { index } ]
				});
			}
			// @ts-ignore
			return deleteRule.apply(this, arguments);
		};

		return () => {
			(targetWindow as any).CSSStyleSheet.prototype.insertRule = insertRule;
			(targetWindow as any).CSSStyleSheet.prototype.deleteRule = deleteRule;
		};
	}

	private serializeEventIfCan(e: WebEventWithTime): WebEventWithTime | string {
		if (this.serializeEvent) {
			return this.serializeEvent(e);
		} else {
			return e;
		}
	}

	private fireEvent(e: WebEventWithTime, isCheckout?: boolean): void {
		this.onEvent(this.serializeEventIfCan(e), isCheckout);
		if (e.type === WebEventType.FullSnapshot) {
			// 全部重抓, 重置状态
			this.lastFullSnapshotEvent = e;
			this.incrementalSnapshotCount = 0;
		} else if (e.type === WebEventType.IncrementalSnapshot) {
			this.incrementalSnapshotCount++;
			const exceedCount = this.checkoutEveryNth && this.incrementalSnapshotCount >= this.checkoutEveryNth;
			const exceedTime = this.checkoutEveryNms && e.timestamp - this.lastFullSnapshotEvent!.timestamp > this.checkoutEveryNms;
			if (exceedCount || exceedTime) {
				this.takeFullSnapshot(true);
			}
		}
	};

	private takeFullSnapshot(isCheckout = false) {
		this.fireEvent(
			wrapEvent({
				type: WebEventType.Meta,
				data: {
					href: window.location.href,
					width: getWindowWidth(),
					height: getWindowHeight()
				}
			}),
			isCheckout
		);
		const [ node, idNodeMap ] = captureSnapshot(window, document, this.blockClass, this.inlineStylesheet, this.maskInputOptions);

		if (!node) {
			return console.warn('Failed to snapshot the document');
		}

		mirror.map = idNodeMap;
		this.fireEvent(wrapEvent({
			type: WebEventType.FullSnapshot,
			data: {
				node,
				initialOffset: {
					left:
						window.pageXOffset !== undefined
							? window.pageXOffset
							: document?.documentElement.scrollLeft ||
							document?.body?.parentElement?.scrollLeft ||
							document?.body.scrollLeft ||
							0,
					top:
						window.pageYOffset !== undefined
							? window.pageYOffset
							: document?.documentElement.scrollTop ||
							document?.body?.parentElement?.scrollTop ||
							document?.body.scrollTop ||
							0
				},
				iconFonts: this.iconFonts
			}
		}));
	}

	public addCustomEvent(tag: string, payload: any) {
		this.fireEvent(wrapEvent({
			type: WebEventType.Custom,
			data: {
				tag,
				payload
			}
		}));
	};
}

export default Recorder;