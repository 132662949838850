import React, { useReducer, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSecurity } from '../hooks/security';
import { isCCICDisplay } from '../utils';
import {
    Button,
    ButtonBar,
    ButtonBarAlignType,
    ButtonType,
    DateTimeRange,
    FormSectionGapVertical,
    PanelSeparator,
    QueryPanel,
    QueryResultColumns,
    QueryResultPanel,
    QueryResultRow,
    Text,
    WorkAreaTitle,
    CheckBox
} from '../component';
import Path from '../path';
// import { doGet } from '../service/ajax';
import { endOfDate, formatFull, startOfDate } from '../service/date';
import {
    TraceCriteria,
    findTenantDynamicQueryCriteria,
    DynamicCriteria, TraceData, TraceDataPage,
} from '../service/trace-service';
import { useShares } from '../hooks/shares';
import { doExport, doPost,uploadExcelFile} from "../service/ajax";
import {DropdownOption} from "../component/dropdown";
import Dropdown from "../component/dropdown";
import {AccountList} from "../service/record-service";
import {getCurrentUserTenantList} from "../service/account-service";
import {downloadFile} from "../service/oss-service";
export interface InspectionReportData{
    inspectionDate?: string;
    tenantCode?: string;
    type?: string;
    source?: string;
    successNum?: string;
    count?: string;
    faultTraceNum?: string;
    faultStepNum?: string;
    faultOssNum?: string;

}

export interface InspectionCriteria{
    createFrom?: string;
    createTo?: string;
    pageNumber?: number;
    pageSize?: number;
    source?: string;
    tenantId?: string;
    allChecked?: boolean;
    type?: string;
    traceDataList?: Array<InspectionReportData>;
}

export interface InspectionReportDataPage {
    pageSize: number;
    pageNumber: number;
    totalPages: number;
    totalItemCount: number;
    errMsg?: string;
    rows?: Array<InspectionReportData & { seq: number, checked?: boolean, createdBy?: string }>
}

export const findTraceInspectionDataPage = async (criteria: InspectionCriteria): Promise<InspectionReportDataPage> => {
    const {  ...rest } = criteria;
    const response = await doPost(`/api/ccic/full/trace/listTraceInspectionReport`, rest);
    const responseData = response.body || {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 0,
        totalItemCount: 0,
        data: []
    };
    const { data, ...pages } = responseData;
    if(pages.errMsg){
        alert(pages.errMsg);
    }
    return {
        ...pages,
        rows: data.map((row: any, index: number) => {
            row.seq = index + 1;
            row.checked=false;
            return row;
        })
    };
};

const TraceQuery = styled.div`
	display: flex;
	flex-direction: column;
`;

const ButtonWrapper = styled.div`
	width: max-content;
	text-align: center;
	> *:last-child {
		display: inline-block;
		margin-left: var(--gap-thin);
	}
`;

const ButtonInputFile = styled.div`
    display: inline-block;
    width: 100px;
    height: 32px;
    position: relative;
    top: 11px;
    border-radius: 4px;
    overflow: hidden;
    >input{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999999;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
`;
type InputCriteria = Exclude<keyof TraceCriteria, 'pageSize' | 'pageNumber'>

export default (props: { taskName?: string}) => {
    const types = new Array<DropdownOption>({value:'',label:'请选择'},{value:'1',label:'databus'},{value:'2',label:'excel'});
    const { taskName } = props;
    const shares = useShares();
    const security = useSecurity();
    const now = new Date();
    const [ isFlag,setIsFlag ] = useState(false);
    const [ isDateReset, setisDateReset ] = useState(false);
    const [tenantList,setTenantList] = useState<Array<DropdownOption> | []>([]);

    const [ allcheckbox ,  setAllcheckbox  ]:any = useState(false);
    const [ , forceUpdate ] = useReducer(x => x + 1, 0);
    const [ data, setData ] = useState<InspectionReportDataPage | null>(null);
    const [ searched, setSearched ] = useState(false);
    const [ dynamicCriteria, setDynamicCriteria ] = useState<Array<DynamicCriteria> | null>(null);

    useEffect(() => {
        (async () => {
            const tenantlist : AccountList =await getCurrentUserTenantList();
            const accounts = tenantlist?.accounts || [];
            const tenant = new Array<DropdownOption>();
            accounts.forEach((account)=>{
                tenant.push({value:account.tenantId,label:account.accountName});
            });
            setTenantList(tenant);
            criteria.tenantId=(accounts[0]?.tenantId.toString());
        })();
    }, []);



    const [ criteria ] = useState<InspectionCriteria>(shares.reachout('__trace_query_criteria_keeped') || {
        createFrom: formatFull(startOfDate(now)),
        createTo: formatFull(endOfDate(now)),
        source: '',
        type: '',
        allChecked:false
    });

        var columns: QueryResultColumns = [
            { label: '#', width: '30px', name: 'seq' },
            { label: '质检时间', width: '230px', name: 'inspectionDate' },
            { label: '系统', width: '100px', name: 'tenantCode' },
            { label: '类型', width: '80px', name: 'type' },
            { label: '来源', width: '80px', name: 'source' },
            { label: '质检数量', width: '100px', name: 'count' },
            { label: '成功数量', width: '100px', name: 'successNum' },
            { label: '缺失数据数量', width: '120px', name: 'faultTraceNum' },
            { label: '缺失步骤数量', width: '120px', name: 'faultStepNum' },
            { label: '缺失文件数量', width: '120px', name: 'faultOssNum' },
        ];

    useEffect(() => {
        (async () => {
            const dynamicCriterias = await findTenantDynamicQueryCriteria();
            setDynamicCriteria(dynamicCriterias);
        })();
    }, []);
    const [ currentCriteria, setCurrentCriteria ] = useState<InspectionCriteria | null>(null);

    const onChanged = (propName: InputCriteria) => (event: React.ChangeEvent) => {
        // @ts-ignore
        criteria[propName] = (event.target as HTMLInputElement).value;
        forceUpdate();
    };


    const onDateChanged = (startDate?: Date, endDate?: Date) => {
        criteria.createFrom = formatFull(startOfDate(startDate));
        criteria.createTo = formatFull(endOfDate(endDate));
        forceUpdate();
    };

    // doGet('https://saletrace-test.ccic-net.com.cn/saletrace/api/trace/list');
    const doSearch = async (pagination: InspectionCriteria) => {
        // 设置当前查询
        setCurrentCriteria(pagination);
        const data = await findTraceInspectionDataPage(pagination);
        setData(data);
        setSearched(true);
    };
    const onSearchClicked = async (criteria: InspectionCriteria) => {
        if(criteria.createFrom! > criteria.createTo!){
            alert('资料生成开始日期不得大于结束日期，请重新选择');
            return;
        }

            if(!criteria.tenantId){
                alert('请选择查询系统');
                return;
            }
            const from = criteria.createFrom!.split("-"),
                to = criteria.createTo!.split("-"),
                year1 = parseInt(from[0]),
                month1 = parseInt(from[1]),
                year2 = parseInt(to[0]),
                month2 = parseInt(to[1]);
            //通过年,月差计算月份差
            //months = (year2 - year1) * 12 + (month2 - month1) + 1;
            if(year1 !== year2 || month1 !== month2){
                alert('仅支持一个月内的查询，请重新选择日期');
                return;
            }


        shares.share('__trace_query_criteria_keeped', criteria);
        const pagination = {
            ...criteria,

        };
        await doSearch(pagination);
    };
    const onResetClicked = () => {
        shares.discard('__trace_query_criteria_keeped');
        // @ts-ignore
        Object.keys(criteria).filter((key) => key !== 'archiveStatuses').forEach((key) => criteria[key as InputCriteria] = '');
        criteria.createFrom = formatFull(startOfDate(now));
        criteria.createTo = formatFull(endOfDate(now));
        setisDateReset(true);
        forceUpdate();
    };

    const onListDownClicked = async (criteria: InspectionCriteria) => {
        if(criteria.createFrom! > criteria.createTo!){
            alert('资料生成开始日期不得大于结束日期，请重新选择');
            return;
        }
        if(isCCICDisplay()){
            if(!criteria.tenantId){
                alert('请选择查询系统');
                return;
            }
            const from = criteria.createFrom!.split("-"),
                to = criteria.createTo!.split("-"),
                year1 = parseInt(from[0]),
                month1 = parseInt(from[1]),
                year2 = parseInt(to[0]),
                month2 = parseInt(to[1]);
            //通过年,月差计算月份差
            //months = (year2 - year1) * 12 + (month2 - month1) + 1;
            if(year1 !== year2 || month1 !== month2){
                alert('仅支持一个月内的查询，请重新选择日期');
                return;
            }
        }


        shares.share('__trace_query_criteria_keeped', criteria);
        const pagination = {
            ...criteria,

        };
        await doDownLoad(pagination);
    };


    const doDownLoad = async (pagination: InspectionCriteria) => {
        // 设置当前查询
        setCurrentCriteria(pagination);
        const {  ...rest } = criteria;
        const result = await doPost(`/api/ccic/full/trace/downLoadInspectionFaultList`, rest);
        if(!result.body){
            alert("下载失败,请联系管理员");
        }else{
            downloadFile(result.body);
        }

    };
    const onPageChanged = async (pageNumber: number) => {
        const pagination = {
            ...currentCriteria,
            pageNumber
        };
        await doSearch(pagination);
    };


    const dataFound = data || { pageNumber: 1, pageSize: 10, totalPages: 1, totalItemCount: 0, rows: [] };
    const rows = (dataFound.rows || []).map((row): QueryResultRow => {
        const data = row as QueryResultRow;
        const onChangeds = () => (event: React.ChangeEvent) => {
                    criteria!.traceDataList!.push({
                        inspectionDate:data.inspectionDate ,
                        tenantCode: data.tenantCode,
                        source: data.source,
                        type:data.data,
                        successNum:data.successNum,
                        count:data.count,
                        faultTraceNum:data.faultTraceNum,
                        faultStepNum:data.faultStepNum
                    });

            forceUpdate();
            // @ts-ignore
        };
        if(isFlag){
            data.checkbox=<CheckBox value={data.checked} onChange={onChangeds()}  />;
        }

        return data;
    });
    const traceUpdate = () => {
        forceUpdate();
    };





    return <TraceQuery>
        <WorkAreaTitle title={taskName ? taskName : "回溯质检统计"}/>
        <QueryPanel>
            <DateTimeRange label="质检日期" columns={2} startDate={criteria.createFrom!} endDate={criteria.createTo!} isReset={isDateReset}
                           onChange={onDateChanged} update={traceUpdate} />
            <Dropdown label="质检类型" placeholder="请输入" value={criteria.type} options={types}  onChange={onChanged('type')}/>
            <Dropdown label="所属系统" options={tenantList} value = {criteria.tenantId} onChange ={onChanged("tenantId")}/>
            <Text label="来源" placeholder="请输入" value={criteria.source} onChange={onChanged('source')}/>


            <PanelSeparator columns={3}/>
            <ButtonBar align={ButtonBarAlignType.CENTER}>
                <Button label="查询" type={ButtonType.PRIMARY} onClick={() => onSearchClicked(criteria)}/>
                <Button label="重置" type={ButtonType.WAIVE} onClick={onResetClicked}/>
                <Button label="失败清单下载" type={ButtonType.PRIMARY} onClick={() => onListDownClicked(criteria)}/>
            </ButtonBar>
            <PanelSeparator columns={3}/>
        </QueryPanel>
        <FormSectionGapVertical/>
        <QueryResultPanel columns={columns} rows={rows} pagination={true} searched={searched}
                          pageCount={dataFound.totalPages} pageNumber={dataFound.pageNumber}
                          totalItemCount={dataFound.totalItemCount}
                          pageChange={onPageChanged}/>
    </TraceQuery>;
}

